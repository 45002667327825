
import { Modifier, ModifierItem } from '@/services/menu/MenuData'
import { reactive, toRefs } from '@vue/reactivity'
import { computed, watch } from '@vue/runtime-core'
import { Options, Vue, setup, prop } from 'vue-class-component'

import { ModifierItemAddElement } from '@/services/cart/ProductInputData'
import ModifierItemDetail from './ModifierItemDetail.vue'
import ModifierItemListItem from './ModifierItemListItem.vue'
import { Nullable } from '@/core/utils/CustomTypes'
import { ElNotification } from 'element-plus'

class ModifierGroupListProps {
  modifier = prop<Modifier>({})
  selectedItems = prop<ModifierItemAddElement[]>({})
}

export interface ModifierItemElement extends ModifierItem {
  ItemIsSelected: boolean
  ItemSelectedQuantity: number
  Modifiers: ModifierItemAddElement[]
}

interface ModifierGroupStateInfo {
  selectedModifier: Nullable<Modifier>
  selectedModifierItem: Nullable<ModifierItemElement>
  itemList: ModifierItemElement[]

  modifierItemDetailRef: Nullable<ModifierItemDetail>
}

@Options({
  name: 'modifier-group-list',
  emits: ['item-modifier-open', 'item-modifier-closed'],
  components: {
    ModifierItemDetail,
    ModifierItemListItem
  }
})
export default class ModifierGroupListItemComponent extends Vue.with(
  ModifierGroupListProps
) {
  context = setup(() => {
    const generateItemList = (
      itemList: ModifierItem[]
    ): ModifierItemElement[] => {
      const currentModifier = this.$props.modifier
      const selectedItems = this.$props.selectedItems ?? []

      return (
        itemList.map((item) => {
          const itemElement = item as ModifierItemElement

          const selectedItem = selectedItems.find(
            (i) =>
              i.ModifierId === currentModifier?.ModifierId &&
              i.ItemId === item.ItemId
          )

          itemElement.ItemIsSelected = !!selectedItem
          itemElement.ItemSelectedQuantity = selectedItem?.ItemQuantity ?? 0
          itemElement.Modifiers = selectedItem?.Modifiers ?? []

          return itemElement
        }) ?? []
      )
    }

    const modifierGroupState = reactive<ModifierGroupStateInfo>({
      selectedModifier: null,
      selectedModifierItem: null,
      itemList: generateItemList(this.$props?.modifier?.ItemList ?? []),

      modifierItemDetailRef: null
    })

    const modifierGroupIsValid = computed<boolean>(() => {
      const modifier = this.$props.modifier
      if (!modifier) {
        return false
      }

      const min = modifier.ModifierMin ?? 0
      const max = modifier.ModifierMax ?? 0

      var selectedQuantity = modifierGroupState.itemList
        .filter((item) => item.ItemIsSelected)
        .reduce((acc, item) => acc + (item.ItemSelectedQuantity ?? 0), 0)

      if (selectedQuantity < min) {
        return false
      } else if (max > 0 && selectedQuantity > max) {
        return false
      }

      return true
    })

    const modifierGroupValue = computed<number>(() => {
      const getItemValue = (itemList: ModifierItemAddElement[]) => {
        return itemList.reduce(
          (agg: number, { Modifiers, ...rest }: ModifierItemAddElement) => {
            let childQuantity = 0
            if (Modifiers) {
              childQuantity = getItemValue(Modifiers)
            }

            return (
              agg +
              (childQuantity + (rest.ItemPrice ?? 0)) * (rest.ItemQuantity ?? 0)
            )
          },
          0
        )
      }

      const modifierGroupValue = modifierGroupState.itemList
        .filter((item) => item.ItemIsSelected)
        .reduce((agg, item) => {
          const childQuantity = getItemValue(item.Modifiers ?? [])
          return (
            agg +
            (childQuantity + (item.ItemPrice ?? 0)) * (item.ItemQuantity ?? 0)
          )
        }, 0)

      return modifierGroupValue
    })

    watch(
      () => this.$props.modifier?.ItemList,
      () => {
        modifierGroupState.itemList = generateItemList(
          this.$props?.modifier?.ItemList ?? []
        )
      }
    )

    const onItemSelected = (item: ModifierItemElement, modifier: Modifier) => {
      modifierGroupState.selectedModifierItem = item
      modifierGroupState.selectedModifier = modifier
      this.$emit('item-modifier-open', item, this.$props.modifier)
    }

    const onItemClosed = (item: ModifierItem) => {
      modifierGroupState.selectedModifierItem = null
      modifierGroupState.selectedModifier = null
      this.$emit('item-modifier-closed', item, this.$props.modifier)
    }

    const itemChangeIsValid = (
      item: ModifierItemElement,
      modifier: Modifier,
      increase = 1
    ): boolean => {
      // Validate if can be selected
      const max = modifier?.ModifierMax ?? 0
      var selectedQuantity = modifierGroupState.itemList
        .filter((item) => item.ItemIsSelected)
        .reduce((acc, item) => acc + (item.ItemSelectedQuantity ?? 0), 0)

      selectedQuantity += increase
      if (max > 0 && selectedQuantity > max) {
        return false
      }

      return true
    }

    const onItemCheckboxChanged = (
      item: ModifierItemElement,
      modifier: Modifier
    ) => {
      let increment = 1
      // IF Radio, clean other radios
      if (modifier.ModifierMin === 1 && modifier.ModifierMax === 1) {
        increment = 0
        modifierGroupState.itemList
          .filter((i) => i.ItemId !== item.ItemId)
          .forEach((i) => {
            i.ItemIsSelected = false
          })
      }

      if (!itemChangeIsValid(item, modifier, increment)) {
        item.ItemIsSelected = false
        item.ItemSelectedQuantity = 0

        ElNotification({
          type: 'error',
          title: 'Límite de modificadores',
          message: 'Has alcanzado el límite de modificadores'
        })

        return
      }

      // Validate if has child modifiers
      if ((item.ModifierList?.length ?? 0) > 0) {
        item.ItemIsSelected = false
        onItemSelected(item, modifier)

        return false
      } else {
        item.ItemSelectedQuantity = 1
      }
    }

    const onItemAdded = (item: ModifierItemElement, modifier: Modifier) => {
      if (!itemChangeIsValid(item, modifier)) {
        item.ItemIsSelected = false
        item.ItemSelectedQuantity = 0

        ElNotification({
          type: 'error',
          title: 'Límite de modificadores',
          message: 'Has alcanzado el límite de modificadores'
        })

        return
      }

      // Validate if has child modifiers
      if ((item.ModifierList?.length ?? 0) > 0) {
        onItemSelected(item, modifier)
      } else {
        item.ItemIsSelected = true
        item.ItemSelectedQuantity = 1
      }
    }

    const onChildItemAdded = (
      item: ModifierItemElement,
      itemQuantity: number,
      modifierList: ModifierItemAddElement[]
    ) => {
      const targetItem = modifierGroupState.itemList.find(
        (i) => i.ItemId === item.ItemId
      )

      if (!targetItem) {
        return
      }

      targetItem.ItemSelectedQuantity = itemQuantity
      targetItem.ItemIsSelected = true
      targetItem.Modifiers = modifierList

      onItemClosed(item)
    }

    const onItemQuantityChanged = (
      item: ModifierItemElement,
      newValue: number,
      oldValue: number,
      modifier: Modifier
    ) => {
      if (!itemChangeIsValid(item, modifier, 0)) {
        const max = modifier?.ModifierMax ?? 0
        var selectedQuantity = modifierGroupState.itemList
          .filter((item) => item.ItemIsSelected)
          .reduce((acc, item) => acc + (item.ItemSelectedQuantity ?? 0), 0)

        let diff = selectedQuantity - max
        diff = diff > 0 ? diff : 0

        const newValue = item.ItemSelectedQuantity - diff
        setTimeout(() => {
          item.ItemSelectedQuantity = newValue
          if (item.ItemSelectedQuantity === 0) {
            item.ItemIsSelected = false
          }

          ElNotification({
            type: 'error',
            title: 'Límite de modificadores',
            message: 'Has alcanzado el límite de modificadores'
          })
        }, 0)
      } else if (item.ItemSelectedQuantity === 0) {
        item.ItemIsSelected = false
      }
    }

    const onItemChildQuantityChanged = (
      item: ModifierItemElement,
      quantity: number,
      modifier: Modifier
    ) => {
      const quantityDiff = item.ItemQuantity ? item.ItemQuantity - quantity : 0

      if (itemChangeIsValid(item, modifier, quantityDiff)) {
        ElNotification({
          type: 'error',
          title: 'Límite de modificadores',
          message: 'Has alcanzado el límite de modificadores'
        })

        return
      }

      const max = modifier?.ModifierMax ?? 0
      var selectedQuantity = modifierGroupState.itemList
        .filter((item) => item.ItemIsSelected)
        .reduce((acc, item) => acc + (item.ItemSelectedQuantity ?? 0), 0)

      let diff = selectedQuantity - max
      diff = diff > 0 ? diff : 0

      const newValue = item.ItemSelectedQuantity - diff
      setTimeout(() => {
        modifierGroupState.modifierItemDetailRef?.context.setItemQuantity(
          newValue
        )
      }, 0)
    }

    return {
      ...toRefs(modifierGroupState),
      modifierGroupIsValid,
      modifierGroupValue,

      onItemSelected,
      onItemClosed,

      onItemCheckboxChanged,
      onItemAdded,
      onChildItemAdded,
      onItemQuantityChanged,
      onItemChildQuantityChanged
    }
  })
}
