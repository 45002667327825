import authAxios from '@/services/core/AuthAxios'
import { AllSections } from '../allsections/AllSections'
import allsectionsService from '../allsections/allsections.service'

import store from '@/store'
import AppWorkspace from '../workspaces/AppWorkspace'
import ServiceHandler from '../core/ServiceHandler'
import ClientSearchData from './ClientSearchData'
import Address from './Address'
import AddressData from './AddressData'
import { Services } from '@/store/enums/StoreEnums'
import { Nullable } from '@/core/utils/CustomTypes'
import ClientList from './ClientList'
import ClientAddData from './ClientAddData'

class ClientService {
  searchClientList (searchString: string) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as Nullable<AppWorkspace>
      const serviceUrl = allSections.getServiceUrl(
        Services.CLIENT_SEARCH,
        workspace
      )

      return authAxios
        .post(serviceUrl, {
          Data: {
            ClientSearch: searchString
          }
        })
        .then(ServiceHandler.checkSuccess<ClientSearchData>(ClientSearchData))
        .catch(ServiceHandler.catchError())
    })
  }

  public async getListForPhone (phone: string) : Promise<ClientList[]> {
    try {
      const allSections = await allsectionsService.get()
      const workspace = store.getters.currentWorkspace as Nullable<AppWorkspace>
      const serviceUrl = allSections.getServiceUrl(
        Services.CLIENT_LIST_BY_PHONE,
        workspace
      )

      const resp = await authAxios
        .post(serviceUrl, {
          Data: {
            ClientPhone: phone
          }
        })
        .then(ServiceHandler.checkSuccess<ClientSearchData>(ClientSearchData))
        .catch(ServiceHandler.catchError())

      return resp.ClientList ?? []
    } catch (err) {
      return Promise.reject(err)
    }
  }

  addClient (client: ClientList) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as Nullable<
        AppWorkspace
      >
      const serviceUrl = allSections.getServiceUrl(
        Services.CLIENT_ADD,
        workspace
      )

      return authAxios
        .post(serviceUrl, {
          Data: {
            UserFirstName: client.UserFirstName,
            UserLastName: client.UserLastName,
            ClientPhone: client.ClientPhone,
            ClientEmail: client.ClientEmail
          }
        })
        .then(ServiceHandler.checkSuccess<ClientAddData>(ClientAddData))
        .catch(ServiceHandler.catchError())
    })
  }

  addAddress (address: Address, clientId?: Nullable<number>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as Nullable<
        AppWorkspace
      >
      const serviceUrl = allSections.getServiceUrl(
        Services.CLIENT_ADD_ADDRESS,
        workspace
      )

      return authAxios
        .post(serviceUrl, {
          Data: {
            ClientId: clientId,
            Address: address
          }
        })
        .then(ServiceHandler.checkSuccess<AddressData>(AddressData))
        .catch(ServiceHandler.catchError())
    })
  }
}

export default new ClientService()
