import { Nullable } from '@/core/utils/CustomTypes'
import authAxios from '@/services/core/AuthAxios'
import store from '@/store'
import { Services } from '@/store/enums/StoreEnums'
import { CartConfigInfo } from '@/store/modules/CartModule'
import { AllSections } from '../allsections/AllSections'
import allsectionsService from '../allsections/allsections.service'
import ClientList from '../clients/ClientList'
import ServiceHandler from '../core/ServiceHandler'
import AppWorkspace from '../workspaces/AppWorkspace'
import CartPaymentLinkResponseData from './CartPaymentLinkResponse'
import CartPaymentStatusResponse from './CartPaymentStatusResponse'
import CartPlaceInput from './CartPlaceInput'
import CartResponseData from './CartResponseData'
import { ProductAddElement } from './ProductInputData'
import ProductResponseData from './ProductResponseData'

class CartService {
  addProduct (product: ProductAddElement, client: Nullable<ClientList>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_ADD_PRODUCT, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            Product: product,
            ClientId: client?.UserId
          }
        })
        .then(ServiceHandler.checkSuccess<ProductResponseData>(ProductResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  removeProduct (orderProductId: number, client: Nullable<ClientList>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_REMOVE_PRODUCT, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            Product: {
              OrderProductId: orderProductId
            },
            ClientId: client?.UserId
          }
        })
        .then(ServiceHandler.checkSuccess<ProductResponseData>(ProductResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  removeProductList (orderProductIdList: number[], client: Nullable<ClientList>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_REMOVE_PRODUCT_LIST, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ProductList: orderProductIdList.map(orderProductId => {
              return {
                OrderProductId: orderProductId
              }
            }),
            ClientId: client?.UserId
          }
        })
        .then(ServiceHandler.checkSuccess<ProductResponseData>(ProductResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  updateProductQuantity (orderProductId: number, quantity: number, client: Nullable<ClientList>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_UPDATE_PRODUCT, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            OrderProduct: {
              OrderProductId: orderProductId,
              ProductQuantity: quantity
            },
            ClientId: client?.UserId
          }
        })
        .then(ServiceHandler.checkSuccess<ProductResponseData>(ProductResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  updateProduct (orderProductId: number, product: ProductAddElement, client: Nullable<ClientList>) {
    return this.removeProduct(orderProductId, client)
      .then(() => this.addProduct(product, client))
  }

  addCoupon (couponCode: string, cartConfig: CartConfigInfo) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_ADD_COUPON, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            CouponCode: couponCode,
            ClientId: cartConfig.Client?.UserId,
            Location: {
              Latitude: cartConfig.Address?.AddressLat,
              Longitude: cartConfig.Address?.AddressLng
            },
            Platform: 'web',
            OrderDate: cartConfig.OrderDate,
            BranchId: cartConfig.Branch?.BranchId,
            OrderIsPickUp: cartConfig.OrderIsPickUp
          }
        })
        .then(ServiceHandler.checkSuccess<ProductResponseData>(ProductResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  getCart (cartConfig: CartConfigInfo) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_GET, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ClientId: cartConfig.Client?.UserId,
            Location: {
              Latitude: cartConfig.Address?.AddressLat,
              Longitude: cartConfig.Address?.AddressLng
            },
            Platform: 'web',
            OrderDate: cartConfig.OrderDate,
            BranchId: cartConfig.Branch?.BranchId,
            OrderIsPickUp: cartConfig.OrderIsPickUp
          }
        })
        .then(ServiceHandler.checkSuccess<CartResponseData>(CartResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  getCartTotals (paymentIsOnline: boolean, cartConfig: CartConfigInfo) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_GET_TOTALS, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ClientId: cartConfig.Client?.UserId,
            Location: {
              Latitude: cartConfig.Address?.AddressLat,
              Longitude: cartConfig.Address?.AddressLng
            },
            Platform: 'web',
            OrderDate: cartConfig.OrderDate,
            BranchId: cartConfig.Branch?.BranchId,
            OrderIsPickUp: cartConfig.OrderIsPickUp,

            OrderUsePoints: false,
            OrderPaymentTypeIsOnline: paymentIsOnline,
            OrderAddressId: cartConfig.Address?.AddressId,
            OrderAddressGoogleId: cartConfig.Address?.AddressGoogleId,
            OrderAddressStreet: cartConfig.Address?.AddressStreet,
            OrderAddressNoExt: cartConfig.Address?.AddressNoExt,
            OrderAddressNoInt: cartConfig.Address?.AddressNoInt,
            OrderAddressZipCode: cartConfig.Address?.AddressZipCode,
            OrderAddressNeighborhood: cartConfig.Address?.AddressNeighborhood
          }
        })
        .then(ServiceHandler.checkSuccess<CartResponseData>(CartResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  validatePlace (order: CartPlaceInput, cartConfig: CartConfigInfo) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_PLACE_VALIDATION, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ClientId: cartConfig.Client?.UserId,
            Order: order
          }
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError())
    })
  }

  place (order: CartPlaceInput, cartConfig: CartConfigInfo, chatId?: Nullable<number>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_PLACE, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ClientId: cartConfig.Client?.UserId,
            ChatId: chatId,
            Order: order
          }
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError())
    })
  }

  placeGuest (order: CartPlaceInput, cartConfig: CartConfigInfo, chatId?: Nullable<number>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_PLACE_GUEST, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ClientId: cartConfig.Client?.UserId,
            ChatId: chatId,
            Order: order,
            Client: {
              ClientFirstName: cartConfig.Client?.UserFirstName,
              ClientLastName: cartConfig.Client?.UserLastName ? cartConfig.Client?.UserLastName : '\0',
              ClientEmail: `${cartConfig.Client?.ClientPhone}@adomicil.io`,
              ClientPhone: cartConfig.Client?.ClientPhone
            }
          }
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError())
    })
  }

  getPaymentLink (order: CartPlaceInput, cartConfig: CartConfigInfo, chatId?: Nullable<number>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_PAYMENT_LINK, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ClientId: cartConfig.Client?.UserId,
            ChatId: chatId,
            Order: order,
            Client: {
              ClientFirstName: cartConfig.Client?.UserFirstName,
              ClientLastName: cartConfig.Client?.UserLastName,
              ClientEmail: `${cartConfig.Client?.ClientPhone}@adomicil.io`,
              ClientPhone: cartConfig.Client?.ClientPhone
            }
          }
        })
        .then(ServiceHandler.checkSuccess<CartPaymentLinkResponseData>(CartPaymentLinkResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  sendPaymentLink (
    paymentIntentId: number,
    phone?: Nullable<string>,
    email?: Nullable<string>,
    chatId?: Nullable<number>) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_SEND_PAYMENT_LINK, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            OrderPaymentIntentId: paymentIntentId,
            ClientPhone: phone,
            ClientEmail: email,
            ChatId: chatId
          }
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError())
    })
  }

  getPaymentStatus (orderId: number, cartConfig: CartConfigInfo) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.CART_PAYMENT_STATUS, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ClientId: cartConfig.Client?.UserId,
            OrderId: orderId
          }
        })
        .then(ServiceHandler.checkSuccess<CartPaymentStatusResponse>(CartPaymentStatusResponse))
        .catch(ServiceHandler.catchError())
    })
  }

  public async setCartInfo (config: CartConfigInfo): Promise<CartResponseData> {
    try {
      const appConfig = await allsectionsService.get()
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = appConfig.getServiceUrl(Services.CART_SET_INFO, workspace)

      const resp = await authAxios
        .post(serviceUrl, {
          Data: {
            ClientId: config.Client?.UserId,
            Location: {
              Latitude: config.Address?.AddressLat,
              Longitude: config.Address?.AddressLng
            },
            Platform: 'web',
            OrderDate: config.OrderDate,
            BranchId: config.Branch?.BranchId,
            OrderIsPickUp: config.OrderIsPickUp
          }
        })
        .then(ServiceHandler.checkSuccess<CartResponseData>(CartResponseData))
        .catch(ServiceHandler.catchError())

      return resp
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new CartService()
