import { Nullable } from '@/core/utils/CustomTypes'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(duration)

export default class DateUtils {
  public static format (date: Nullable<dayjs.Dayjs>, format?: string) : Nullable<string> {
    return date?.format(format)
  }

  public static getUtcNow (): dayjs.Dayjs {
    return dayjs.utc()
  }

  public static formatUtcString (dateString: string, format?: string) : Nullable<string> {
    return dayjs
      .utc(dateString)
      .local()
      .format(format ?? 'DD MMM YYYY')
  }

  public static parseUtcString (dateString: string) : Date {
    return dayjs
      .utc(dateString)
      .local()
      .toDate()
  }

  public static timeAgo (dateString: string) : string {
    return dayjs
      .utc(dateString)
      .local()
      .fromNow(true)
  }

  public static getDiff (date1: Date, date2: Date) {
    const d1 = dayjs.default(date1)
    const d2 = dayjs.default(date2)

    return d1.diff(d2, 'second')
  }

  public static toLiteral () {
    return {
      format: this.format,
      getUtcNow: this.getUtcNow,
      formatUtcString: this.formatUtcString,
      parseUtcString: this.parseUtcString,
      timeAgo: this.timeAgo,
    }
  }
}
