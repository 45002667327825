import { Nullable } from '@/core/utils/CustomTypes'
import SerializableObject from '../core/SerializableObject'

export default class AppWorkspace extends SerializableObject {
  AppId: Nullable<number>
  AppKeyId: Nullable<string>
  AppName: Nullable<string>
  AppIconUrl: Nullable<string>
  AppServiceUrl: Nullable<string>
  AppHashIsEnabled: Nullable<boolean>
  AppGoogleCallcenterKey: Nullable<string>
  AppHasDivisonGroups: Nullable<boolean>

  constructor (
    appId: Nullable<number> = null,
    appKey: Nullable<string> = null,
    appName: Nullable<string> = null,
    appIconUrl: Nullable<string> = null,
    appServiceurl: Nullable<string> = null,
    appHashIsEnabled: Nullable<boolean> = false,
    appGoogleCallcenterKey: Nullable<string> = null,
    appHasDivisonGroups: Nullable<boolean> = null) {
    super()

    this.AppId = appId
    this.AppKeyId = appKey
    this.AppName = appName
    this.AppIconUrl = appIconUrl
    this.AppServiceUrl = appServiceurl
    this.AppHashIsEnabled = appHashIsEnabled
    this.AppGoogleCallcenterKey = appGoogleCallcenterKey
    this.AppHasDivisonGroups = appHasDivisonGroups
  }
}
