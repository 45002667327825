
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'message-out',
  props: {
    name: String,
    image: String,
    time: String,
    text: String
  },
  setup () {
    const urlify = (text: string) => {
      var urlRegex = /(https?:\/\/[^\s]+)/g
      const finalText = text?.replace(urlRegex, (url: string) => {
        return `<a href="${url}" target="_blank">${url}</a>`
      })

      return `<p class="m-0">${finalText}</p>`
    }

    return {
      urlify
    }
  }
})
