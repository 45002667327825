import { Nullable } from '@/core/utils/CustomTypes'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations } from '../enums/StoreEnums'

export interface ModalStateInfo {
  modalId: Nullable<string>
}

@Module
export default class ModalModule extends VuexModule implements ModalStateInfo {
  modalId: Nullable<string> = null

  get currentModalId () {
    return this.modalId
  }

  @Mutation
  [Mutations.SET_MODAL_ID] (modalId: Nullable<string>) : void {
    this.modalId = modalId
  }

  @Action
  [Actions.SHOW_MODAL] (modalId: string) : void {
    this.context.commit(Mutations.SET_MODAL_ID, modalId)
  }

  @Action
  [Actions.HIDE_ALL_MODALS] () : void {
    this.context.commit(Mutations.SET_MODAL_ID, null)
  }
}
