
import { CustomEvents, Nullable } from '@/core/utils/CustomTypes'
import { CallModel, CallState } from '@/utils/callproviders/CallProviderUtil'
import { VonageUtil } from '@/utils/callproviders/utils/vonage.util'
import { reactive, toRefs } from '@vue/reactivity'
import { computed, onBeforeUnmount, onMounted } from '@vue/runtime-core'
import { Options, setup, Vue } from 'vue-class-component'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import clientService from '@/services/clients/client.service'
import ImageLoad from '@/components/widgets/images/ImageLoad.vue'
import DateUtils from '@/utils/DateUtils'
import Moveable from 'vue3-moveable'
import { Emitter } from 'mitt'
import { inject } from 'vue'
import { callService } from '@/services/call/call.service'
import { CallProviderType } from '@/utils/callproviders/CallProviderType'
import { stat } from 'fs'

interface CallPopupState {
  call: Nullable<CallModel>
  timer: Nullable<any>,
  elapsedTime: Nullable<number>
}

@Options({
  components: {
    ImageLoad,
    Moveable
  }
})
export default class CallPopupComponent extends Vue {
  context = setup(() => {
    const emitter = inject<Emitter<CustomEvents>>('emitter')
    const state = reactive<CallPopupState>({
      call: null,
      timer: null,
      elapsedTime: null
    })

    const test = false
    if (test) {
      state.call = new CallModel({
        id: 'test',
        from: '6691115253',
        callProvider: {
          CallSourceId: 1,
          CallProviderId: 'vonage',
          CallSourceNumber: '+525599902868',
          CallSourceUserId: 'USR-6c597d1f-62b8-4412-aca4-d6c7220011a9',
          CallSourceUserName: '+525599902868',
          CallSourceUserToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2NTM2MDQyODMsImV4cCI6MTY4NTE0MDI4MywianRpIjoiZmZiMWJiZDYtZWZmNS00ZDZkLWI4ZWQtYTM3M2U2ZjBjNjY2IiwiYXBwbGljYXRpb25faWQiOiIzYjc3MDc4OC02ZGNjLTQ5N2YtYWMyMy1iZDhjOTQyM2FmZTciLCJzdWIiOiIrNTI1NTk5OTAyODY4IiwiYWNsIjp7InBhdGhzIjp7Ii8qL3VzZXJzLyoqIjp7fSwiLyovY29udmVyc2F0aW9ucy8qKiI6e30sIi8qL3Nlc3Npb25zLyoqIjp7fSwiLyovZGV2aWNlcy8qKiI6e30sIi8qL2ltYWdlLyoqIjp7fSwiLyovbWVkaWEvKioiOnt9LCIvKi9hcHBsaWNhdGlvbnMvKioiOnt9LCIvKi9wdXNoLyoqIjp7fSwiLyova25vY2tpbmcvKioiOnt9LCIvKi9sZWdzLyoqIjp7fX19fQ.a6cTct5bDAxuYlldJgzcfN_0KbrGj2jMwV53_9OZsAKGnvzsDtaF0xwfOozLjzuahwHUKGMWJtQ3viR_P5yg1V_rFGDNwR8D6aaOgB6-jvGaMWClfQ1kqYLiz9H-llNm3ASvLxmR92K5AQTN3e_C6xg7sUpLXTJKZB-NXlnxC2c0wlvY00PzHHj1c22PwvEuEE4NUCRkwl2nxRZsjYpnJRQRELrJ8n4Xr6YHibyjzLJ6dmC-kQLzDkK2LT5mey_3TLyRkbHnTu7_PFlCnu0yax4L_l7GbTzPyTM7l42G7bszunmwmLGH1s-4Eou6B4V0gGho03f7_wQbvRG8rJ0dmw'
        },
        metadata: {},
        onAnswer: () => {
          // test only
        },
        onHangUp: () => {
          // test only
        },
        onToggleMute: () => {
          // test only
        }
      })

      state.call.state = CallState.Ringing
    }

    const visible = computed<boolean>(() => {
      // return true
      return !!state.call
    })

    const audio = new Audio('/media/audio/call.wav')
    audio.loop = true

    const onCallReceived = (call) => {
      if (state.call) {
        return
      }

      this.playAudio(audio)
      state.call = call

      clientService.getListForPhone(call.from)
        .then((clients) => {
          const nonGuestUsers = clients.filter(c => !c.ClientIsGuest)

          const client = nonGuestUsers.find(c => !c.ClientIsCallcenter) ?? clients[0]
          if (client && state.call) {
            state.call.client = client
          }
        })
        .catch(() => {
          // ignore
        })
    }

    const onCallEnded = (call) => {
      this.stopAudio(audio)

      if (!state.call || state.call.id !== state.call.id) {
        return
      }

      state.call.state = call.state
      state.call.metadata = call.metadata

      state.timer && clearInterval(state.timer)

      setTimeout(() => {
        state.call = null
      }, 3 * 1000)
    }

    const onCallAnswered = (call) => {
      this.stopAudio(audio)

      if (!state.call || state.call.id !== state.call.id) {
        return
      }

      if (state.call.state === CallState.Ringing) {
        state.call = null
      }
    }

    onMounted(() => {
      callService.getActiveSourceList()
        .then((sources) => {
          const providers = sources.CallSourceList
            .filter(p => p.CallProviderId === CallProviderType.Vonage)

          providers.forEach((provider) => {
            const vonage = new VonageUtil(provider)

            vonage.onCallReceived(onCallReceived)
            vonage.onCallEnded(onCallEnded)
            vonage.onCallAnswered(onCallAnswered)

            vonage.init()
          })

          if (providers.length > 0) {
            this.verifyAudio()
          }
        })
        .catch(() => {
          // ignored
        })
    })

    onBeforeUnmount(() => {
      this.context.timer && clearInterval(this.context.timer)
    })

    return {
      ...toRefs(state),
      visible,
      CallState,
      audio,
      emitter
    }
  })

  public answer () : void {
    this.stopAudio(this.context.audio)
    if (!this.context.call) {
      return
    }

    this.context.call.answer()

    const time = DateUtils.getDiff(
      new Date(),
      this.context.call?.startDate ?? new Date()
    )

    this.context.elapsedTime = time

    this.context.timer = setInterval(() => {
      const time = DateUtils.getDiff(
        new Date(),
        this.context.call?.startDate ?? new Date()
      )

      this.context.elapsedTime = time
    }, 1000)

    this.context.emitter?.emit('userSelected', this.context.call.from)
  }

  public ignore () : void {
    this.stopAudio(this.context.audio)
    if (!this.context.call) {
      return
    }

    this.context.call = null
  }

  public hangUp () : void {
    if (!this.context.call) {
      return
    }

    this.context.call.hangUp()
  }

  public toggleMute () : void {
    if (!this.context.call) {
      return
    }

    this.context.call.toggleMute()
  }

  public getCallTimer (time: Nullable<number>) : Nullable<string> {
    if (!time) {
      return null
    }

    const minutes = Math.floor(time / 60)
    const seconds = time - (minutes * 60)

    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  }

  public getCallMessage (call: Nullable<CallModel>) {
    if (!call) {
      return ''
    }

    if (call.state === CallState.Ended) {
      return 'Llamada terminada'
    }

    if (call.state === CallState.Active) {
      return 'Llamada activa'
    }

    return 'Llamada entrante'
  }

  public getCallColor (call: Nullable<CallModel>) {
    if (!call) {
      return ''
    }

    if (call.state === CallState.Ended) {
      return 'danger'
    }

    if (call.state === CallState.Active) {
      return 'primary'
    }

    return 'success'
  }

  public onDrag ({ target, transform }) {
    target.style.transform = transform
  }

  private verifyAudio () {
    setTimeout(async () => {
      const testAudio = new Audio('/media/audio/call.wav')
      try {
        await testAudio.play()
        testAudio.pause()
      } catch (e) {
        Swal.fire({
          title: 'Interacción requerida',
          icon: 'info',
          text: 'Para habilitar las notificaciones con sonido y garantizar el correcto funcionamiento de las llamadas debes dar click en el boton de aceptar.',
          confirmButtonText: 'Aceptar'
        })
      }
    }, 5 * 1000)
  }

  private playAudio (audio: HTMLAudioElement) {
    this.stopAudio(audio)
    audio?.play()
  }

  private stopAudio (audio: HTMLAudioElement) {
    if (!audio) {
      return
    }

    audio.pause()
    audio.currentTime = 0
  }
}
