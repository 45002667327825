import SerializableObject from '../core/SerializableObject'

export interface CartPaymentStatus {
  OrderId?: number
  OrderPaymentStatusId?: string
  OrderPaymentStatusName?: string
  OrderPaymentSpecificStatusId?: string
  OrderPaymentSpecificStatusMessage?: string
  OrderPaymentIntentReloadLink?: boolean
}

export default class CartPaymentStatusResponse extends SerializableObject {
  Order: CartPaymentStatus = {}
}
