
import Message from '@/services/chat/Message'
import DateUtils from '@/utils/DateUtils'
import { Options, Vue, setup, prop } from 'vue-class-component'

import MessageAttachment from './MessageAttachment.vue'

class MessageInProps {
  message = prop<Message>({})
}

@Options({
  name: 'message-in',
  components: {
    MessageAttachment
  },
  emits: ['file-loaded']
})
export default class MessageInComponent extends Vue.with(MessageInProps) {
  context = setup(() => {
    const urlify = (message: Message) => {
      var urlRegex = /(https?:\/\/[^\s]+)/g
      let finalText = message?.MessageBody?.replace(urlRegex, (url: string) => {
        return `<a href="${url}" target="_blank">${url}</a>`
      })

      if (message?.MessageIsUnsupported) {
        finalText = 'Mensaje no soportado'
      }

      return `<p class="m-0">${finalText ?? ''}</p>`
    }

    const onFileLoaded = () => {
      this.$emit('file-loaded')
    }

    return {
      urlify,
      ...DateUtils.toLiteral(),
      onFileLoaded
    }
  })
}
