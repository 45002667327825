
import Message, { MessageType } from '@/services/chat/Message'
import { Options, prop, setup, Vue } from 'vue-class-component'
import { debounce } from 'debounce'

class MessageAttachmentProps {
  message = prop<Message>({})
}

@Options({
  name: 'message-attachment',
  emits: ['file-loaded']
})
export default class MessageAttachmentComponent extends Vue.with(MessageAttachmentProps) {
  context = setup(() => {
    const hasImage = (message?: Message) : boolean => {
      return !!message?.MessageAttachmentUrl && message?.MessageType === MessageType.Image
    }

    const hasDocument = (message?: Message) : boolean => {
      return !!message?.MessageAttachmentUrl && message?.MessageType !== MessageType.Image
    }

    const hasLocation = (message?: Message) : boolean => {
      return !!message?.MessageLatitude && !!message?.MessageLongitude && message?.MessageType === MessageType.Location
    }

    const messageTypeNameMap = {
      [MessageType.Pdf]: 'Pdf',
      [MessageType.Audio]: 'Audio',
      [MessageType.Video]: 'Video',
      [MessageType.Location]: 'Ubicación'
    }

    const getDocumentName = (message: Message) => {
      if (message.MessageBody && message.MessageType === MessageType.Pdf) {
        return message.MessageBody
      }

      const typeName = messageTypeNameMap?.[message.MessageType ?? ''] ?? message.MessageType
      return typeName?.toUpperCase()
    }

    const messageTypeIconMap = {
      [MessageType.Pdf]: 'file-pdf',
      [MessageType.Audio]: 'headphones',
      [MessageType.Video]: 'video',
      [MessageType.Location]: 'map-pin'
    }

    const getIcon = (message: Message) => {
      const icon = messageTypeIconMap?.[message.MessageType ?? '']
      return icon ?? 'file'
    }

    const messageTypeColorMap = {
      [MessageType.Pdf]: 'danger',
      [MessageType.Audio]: 'primary',
      [MessageType.Video]: 'warning',
      [MessageType.Location]: 'success'
    }

    const getColor = (message: Message) => {
      const color = messageTypeColorMap?.[message.MessageType ?? '']
      return color ?? 'dark'
    }

    const getLocationUrl = (message: Message) => {
      const url = `https://www.google.com/maps/search/?api=1&query=${message.MessageLatitude},${message.MessageLongitude}`
      return url
    }

    const imageLoaded = () => {
      this.$emit('file-loaded')
    }

    return {
      hasImage,
      hasDocument,
      hasLocation,
      getDocumentName,
      getColor,
      getIcon,
      getLocationUrl,
      imageLoaded
    }
  })
}
