
import { Modifier } from '@/services/menu/MenuData'
import { Options, prop, setup, Vue } from 'vue-class-component'
import { ModifierItemElement } from './ModifierGroupListItem.vue'

class ModifierItemListItemProps {
  item = prop<ModifierItemElement>({})
  modifier = prop<Modifier>({})
}

@Options({
  name: 'modifier-item-list-item',
  emits: [
    'item-selected',
    'item-checked',
    'item-added',
    'item-quantity-changed'
  ],
  components: {}
})
export default class ModifierItemListItemComponent extends Vue.with(
  ModifierItemListItemProps
) {
  context = setup(() => {
    const onItemSelected = (item: ModifierItemElement, modifier: Modifier) => {
      this.$emit('item-selected', item, modifier)
    }

    const onItemCheckboxChanged = (
      item: ModifierItemElement,
      modifier: Modifier
    ) => {
      // If is unselected clean and finish
      if (!item.ItemIsSelected) {
        item.ItemSelectedQuantity = 0
        item.Modifiers = []

        return
      }

      this.$emit('item-checked', item, modifier)
    }

    const onItemAdded = (item: ModifierItemElement, modifier: Modifier) => {
      this.$emit('item-added', item, modifier)
    }

    const onItemQuantityChanged = (currentValue: number, oldValue: number) => {
      this.$emit(
        'item-quantity-changed',
        this.$props.item,
        currentValue,
        oldValue,
        this.$props.modifier
      )
    }

    return {
      onItemSelected,
      onItemCheckboxChanged,
      onItemAdded,
      onItemQuantityChanged
    }
  })
}
