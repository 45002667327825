const DocMenuConfig: any[] = [
  {
    pages: [
      {
        heading: 'newOrder',
        route: '/order/new',
        svgIcon: '/media/icons/duotone/Navigation/Plus.svg',
        fontIcon: 'bi-app-indicator'
      }
    ]
  },
  {
    heading: 'orders',
    route: '/order',
    pages: [
      {
        heading: 'activeOrders',
        route: '/order/active',
        svgIcon: '/media/icons/duotone/Shopping/Box3.svg',
        fontIcon: 'bi-app-indicator'
      },
      {
        heading: 'historyOrders',
        route: '/order/history',
        svgIcon: '/media/icons/duotone/Interface/Calendar.svg',
        fontIcon: 'bi-app-indicator'
      }
    ]
  }
]

export default DocMenuConfig
