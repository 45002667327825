import { createI18n } from 'vue-i18n'

const messages = {
  es: {
    newOrder: 'Nueva orden',
    orders: 'Órdenes',
    activeOrders: 'Activas',
    historyOrders: 'Historial',

    dashboard: 'Tablero',
    layoutBuilder: 'Constructor de maquetación',
    craft: 'Elaborado',
    pages: 'Paginas',
    profile: 'Perfil',
    profileOverview: 'Descripción general',
    projects: 'Proyectos',
    campaigns: 'Campañas',
    documents: 'Documentos',
    connections: 'Conexiones',
    wizards: 'Magos',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    account: 'Cuenta',
    accountOverview: 'Descripción general',
    settings: 'Ajustes',
    authentication: 'Autenticación',
    basicFlow: 'Flujo básico',
    signIn: 'Registrarse',
    signUp: 'Inscribirse',
    passwordReset: 'Restablecimiento de contraseña',
    error404: 'Error 404',
    error500: 'Error 500',
    apps: 'Aplicaciones',
    chat: 'Chat',
    privateChat: 'Chat privado',
    groupChat: 'Grupo de chat',
    drawerChat: 'Chat del cajón',
    widgets: 'Widgets',
    widgetsLists: 'Liza',
    widgetsStatistics: 'Estadísticas',
    widgetsCharts: 'Gráficos',
    widgetsMixed: 'Mezclada',
    widgetsTables: 'Mesas',
    widgetsFeeds: 'Alimenta',
    changelog: 'Registro de cambios',
    docsAndComponents: 'Documentos & Componentes',
    megaMenu: 'Mega menú',
    exampleLink: 'Enlace de ejemplo',
    modals: 'Modales',
    general: 'General',
    inviteFriends: 'Invitar A Amigos',
    viewUsers: 'Ver Usuarios',
    upgradePlan: 'Plan De Actualización',
    shareAndEarn: 'Compartir Y Ganar',
    forms: 'Formas',
    newTarget: 'Nuevo Objetivo',
    newCard: 'Nueva Tarjeta',
    newAddress: 'Nueva Direccion',
    createAPIKey: 'Crea Clave De Api',
    twoFactorAuth: 'Dos Factores',
    createApp: 'Crear Aplicacion',
    createAccount: 'Crear Una Cuenta'
  },
  en: {
    newOrder: 'New order',

    dashboard: 'Dashboard',
    layoutBuilder: 'Layout builder',
    craft: 'Crafted',
    pages: 'Pages',
    profile: 'Profile',
    profileOverview: 'Overview',
    projects: 'Projects',
    campaigns: 'Campaigns',
    documents: 'Documents',
    connections: 'Connections',
    wizards: 'Wizards',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    account: 'Account',
    accountOverview: 'Overview',
    settings: 'Settings',
    authentication: 'Authentication',
    basicFlow: 'Basic Flow',
    signIn: 'Sign-in',
    signUp: 'Sign-up',
    passwordReset: 'Password Reset',
    error404: 'Error 404',
    error500: 'Error 500',
    apps: 'Apps',
    chat: 'Chat',
    privateChat: 'Private Chat',
    groupChat: 'Group Chat',
    drawerChat: 'Drawer Chat',
    widgets: 'Widgets',
    widgetsLists: 'Lists',
    widgetsStatistics: 'Statistics',
    widgetsCharts: 'Charts',
    widgetsMixed: 'Mixed',
    widgetsTables: 'Tables',
    widgetsFeeds: 'Feeds',
    changelog: 'Changelog',
    docsAndComponents: 'Docs & Components',
    megaMenu: 'Mega Menu',
    exampleLink: 'Example link',
    modals: 'Modals',
    general: 'General',
    inviteFriends: 'Invite Friends',
    viewUsers: 'View Users',
    upgradePlan: 'Upgrade Plan',
    shareAndEarn: 'Share & Earn',
    forms: 'Forms',
    newTarget: 'New Target',
    newCard: 'New Card',
    newAddress: 'New Address',
    createAPIKey: 'Create API Key',
    twoFactorAuth: 'Two Factor Auth',
    createApp: 'Create App',
    createAccount: 'Create Account'
  }
}

const i18n = createI18n({
  legacy: false,
  locale: 'es',
  globalInjection: true,
  messages
})

export default i18n
