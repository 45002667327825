import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bg-light text-inverse-light p-3 fw-bold fw-6 d-flex flex-row" }
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "flex-shrink-0" }
const _hoisted_4 = {
  key: 0,
  class: "mx-2"
}
const _hoisted_5 = {
  key: 1,
  class: "mx-2"
}
const _hoisted_6 = {
  key: 2,
  class: "mx-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModifierItemListItem = _resolveComponent("ModifierItemListItem")
  const _component_ModifierItemDetail = _resolveComponent("ModifierItemDetail")

  return (_openBlock(), _createBlock("div", null, [
    _withDirectives(_createVNode("div", null, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, _toDisplayString(_ctx.modifier?.ModifierName), 1),
        _createVNode("div", _hoisted_3, [
          (_ctx.modifier?.ModifierMin > 0)
            ? (_openBlock(), _createBlock("span", _hoisted_4, " MIN: " + _toDisplayString(_ctx.modifier?.ModifierMin), 1))
            : _createCommentVNode("", true),
          (_ctx.modifier?.ModifierMax > 0)
            ? (_openBlock(), _createBlock("span", _hoisted_5, " MAX: " + _toDisplayString(_ctx.modifier?.ModifierMax), 1))
            : _createCommentVNode("", true),
          (!(_ctx.modifier?.ModifierMin > 0 && _ctx.modifier?.ModifierMax))
            ? (_openBlock(), _createBlock("span", _hoisted_6, " OPCIONAL "))
            : _createCommentVNode("", true)
        ])
      ]),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.context.itemList, (item, index) => {
        return (_openBlock(), _createBlock(_component_ModifierItemListItem, {
          key: index,
          item: item,
          modifier: _ctx.modifier,
          onItemSelected: _ctx.context.onItemSelected,
          onItemChecked: _ctx.context.onItemCheckboxChanged,
          onItemAdded: _ctx.context.onItemAdded,
          onItemQuantityChanged: _ctx.context.onItemQuantityChanged
        }, null, 8, ["item", "modifier", "onItemSelected", "onItemChecked", "onItemAdded", "onItemQuantityChanged"]))
      }), 128))
    ], 512), [
      [_vShow, !_ctx.context.selectedModifierItem]
    ]),
    (_ctx.context.selectedModifierItem)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: ["h-100", { 'd-flex flex-column': _ctx.context.selectedModifierItem }]
        }, [
          _createVNode(_component_ModifierItemDetail, {
            ref: 
          (el) => {
            _ctx.context.modifierItemDetailRef = el
          }
        ,
            item: _ctx.context.selectedModifierItem,
            modifier: _ctx.context.selectedModifier,
            onItemAdded: _ctx.context.onChildItemAdded,
            onItemDetailClosed: _ctx.context.onItemClosed,
            onItemQuantityChanged: _ctx.context.onItemChildQuantityChanged
          }, null, 8, ["item", "modifier", "onItemAdded", "onItemDetailClosed", "onItemQuantityChanged"])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}