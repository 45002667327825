
import { defineComponent } from 'vue'
import KTTopbar from '@/layout/header/Topbar.vue'
import KTMenu from '@/layout/header/Menu.vue'
import { version, isDocPage } from '@/core/helpers/documentation'

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay
} from '@/core/helpers/config'

export default defineComponent({
  name: 'KTHeader',
  props: {
    title: String
  },
  components: {
    KTTopbar,
    KTMenu
  },
  setup () {
    return {
      isDocPage,
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      version
    }
  }
})
