import { Nullable } from "@/core/utils/CustomTypes"
import AppWorkspace from "@/services/workspaces/AppWorkspace"
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"

export interface WorkspaceInfo {
    workspace: Nullable<AppWorkspace>
}

@Module
export default class WorkspaceModule extends VuexModule implements WorkspaceInfo {
    workspace = (() : Nullable<AppWorkspace> => {
        const workspaceJson = JSON.parse(localStorage.getItem('workspace') ?? 'null')
        const initialWorkspace = workspaceJson
            ? new AppWorkspace().from(workspaceJson)
            : null

        return initialWorkspace
    })()

    get workspaceIsSelected(): boolean {
        return !!this.currentWorkspace
    }

    get currentWorkspace(): Nullable<AppWorkspace> {
        return this.workspace
    }

    @Mutation
    [Mutations.SET_WORKSPACE](workspace: AppWorkspace) {
        this.workspace = workspace
        localStorage.setItem('workspace', JSON.stringify(workspace))
    }

    @Mutation
    [Mutations.PURGE_WORKSPACE]() {
        this.workspace = null
        localStorage.removeItem('workspace')
    }

    @Action
    [Actions.SELECT_WORKSPACE](workspace: AppWorkspace) {
        this.context.commit(Mutations.SET_WORKSPACE, workspace)
    }

    @Action
    [Actions.CLEAR_WORKSPACE]() {
        this.context.commit(Mutations.PURGE_WORKSPACE)
    }
}