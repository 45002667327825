import { Nullable } from "@/core/utils/CustomTypes";
import SerializableObject from "../core/SerializableObject";
import { User } from "./User";

class UserLoginData extends SerializableObject {
    User: Nullable<User> = null

    constructor(user?: User) {
        super()

        this.User = user ?? null
    }
}

export default UserLoginData
