
import { computed, defineComponent, inject, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { MenuComponent } from '@/assets/ts/components'
import MainMenuConfig from '@/core/config/MainMenuConfig'
import { headerMenuIcons } from '@/core/helpers/config'
import { Actions } from '@/store/enums/StoreEnums'
import { CustomEvents } from '@/core/utils/CustomTypes'
import { Emitter } from 'mitt'
import { useStore } from 'vuex'

import Swal from 'sweetalert2/dist/sweetalert2.min.js'

export default defineComponent({
  name: 'KTMenu',
  components: {},
  setup () {
    const { t, te } = useI18n()
    const route = useRoute()
    const store = useStore()
    const emitter = inject<Emitter<CustomEvents>>('emitter')

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1
    }

    const isNewOrderView = computed(() => route.name === 'new-order')

    const translate = text => {
      if (te(text)) {
        return t(text)
      } else {
        return text
      }
    }

    onMounted(() => {
      MenuComponent.reinitialization()
    })

    const clearAll = () => {
      store.dispatch(Actions.CART_CLEAR_ALL)
      emitter?.emit('clearOrder', '')
    }

    const cancelOrder = () => {
      store
        .dispatch(Actions.CART_EMPTY)
        .then(() => {
          store.dispatch(Actions.CART_CLEAR_ALL)
          emitter?.emit('clearOrder', '')
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    return {
      hasActiveChildren,
      isNewOrderView,
      headerMenuIcons,
      MainMenuConfig,
      translate,
      clearAll,
      cancelOrder
    }
  }
})
