import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Layout from '../layout/Layout.vue'
import Login from '../views/Login.vue'
import store from '@/store'

import { Actions } from '@/store/enums/StoreEnums'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    // component: () => import('@/layout/Layout.vue'),
    component: Layout,
    redirect: '/order/new',
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: '/order/new',
        name: 'new-order',
        component: () => import('@/views/orders/NewOrder.vue'),
        meta: {
          requiresAuth: true,
          header: 'Nueva orden'
        }
      },
      {
        path: '/order/active',
        name: 'active-orders',
        component: () => import('@/views/orders/ActiveOrders.vue'),
        meta: {
          requiresAuth: true,
          header: 'Órdenes activas'
        }
      },
      {
        path: '/order/history',
        name: 'history-orders',
        component: () => import('@/views/orders/HistoryOrders.vue'),
        meta: {
          requiresAuth: true,
          header: 'Historial de órdenes'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guestOnly: true
    }
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/error/Error404.vue')
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/error/Error500.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some(record => record.meta?.requiresAuth ?? false)
  const guestOnly = to.matched.some(record => record.meta?.guestOnly ?? false)

  const loggedIn = store.getters.isAuthenticated
  store.dispatch(Actions.VERIFY_AUTH)

  if (authRequired && !loggedIn) {
    next('/login')
  } else if (guestOnly && loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
