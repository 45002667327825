import { initializeApp } from 'firebase/app'
import { getMessaging, onMessage } from 'firebase/messaging'
import emitter from '@/utils/Emitter'
import { debounce } from 'debounce'
import store from '@/store'
import AppWorkspace from '@/services/workspaces/AppWorkspace'

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyBi_2ByB1iefy6LUN6NeKEQ-capGGCkF9E',
  authDomain: 'adomicilio-callcenter.firebaseapp.com',
  projectId: 'adomicilio-callcenter',
  storageBucket: 'adomicilio-callcenter.appspot.com',
  messagingSenderId: '838601975513',
  appId: '1:838601975513:web:abe2846002a997b46e80c8',
  measurementId: 'G-X0CZQ06ZR7'
})

const messaging = getMessaging(firebaseApp)

const newMessageReceived = debounce((payload: any) => {
  const data = payload.type ? payload.data ?? payload : payload
  console.log('Message received. ', data)

  const metadata = JSON.parse(data?.data?.metadata ?? '{}')
  const workspace = store.getters.currentWorkspace as AppWorkspace

  if (metadata.AppKey !== workspace?.AppKeyId) {
    return
  }

  console.log('Chat update triggered')
  emitter?.emit('chatUpdate', '')
}, 250, true)

onMessage(messaging, newMessageReceived)
navigator.serviceWorker?.addEventListener('message', newMessageReceived)

export default messaging
