import axios from 'axios'
import ServiceHandler from '@/services/core/ServiceHandler'
import { AllSections } from '@/services/allsections/AllSections'
import ServiceResponse from '../core/ServiceResponse'
import { Constans } from '@/store/enums/StoreEnums'

class AllSectionsService {
  get() {
    const allSectionsJson = JSON.parse(localStorage.getItem('allSections') ?? 'null')
    if (allSectionsJson) {
      const allSections = new AllSections().from(allSectionsJson)
      
      if (allSections.isValid()) {
        return Promise.resolve(allSections)
      }

      console.info('AllSections expirado, volviendo a descargar...')
    }

    return axios
      .post<ServiceResponse<AllSections>>('/App/AllSectionsWS/GetJson', {})
      .then(ServiceHandler.checkSuccess<AllSections>(AllSections, (data: AllSections) => {
        data.timestamp = new Date().getTime()
        data.appVersion = Constans.APP_VERSION
        
        localStorage.setItem('allSections', JSON.stringify(data))
      }))
      .catch(ServiceHandler.catchError())
  }
}

export default new AllSectionsService()
