
import { Modifier, ModifierItem } from '@/services/menu/MenuData'
import { reactive, toRefs } from '@vue/reactivity'
import { computed, watch } from 'vue'
import { Options, Vue, setup, prop } from 'vue-class-component'

import { ModifierItemAddElement } from '@/services/cart/ProductInputData'
import MenuGroupListItem, {
  ModifierItemElement
} from './ModifierGroupListItem.vue'

import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { Nullable } from '@/core/utils/CustomTypes'

class ModifierItemDetailProps {
  item = prop<ModifierItemElement>({})
  modifier = prop<Modifier>({})
}

export interface ModifierElement extends Modifier {
  modifierGroupRef?: Nullable<MenuGroupListItem>
  selectedItems?: ModifierItemAddElement[]
}

interface ItemDetailStateInfo {
  selectedModifierItemNode: Nullable<ModifierItem>
  modifierList: ModifierElement[]
  itemQuantity: number
}

@Options({
  name: 'modifier-item-detail',
  emits: ['item-added', 'item-detail-closed', 'item-quantity-changed'],
  components: {
    // MenuGroupListItem
  }
})
export default class ModifierItemDetailComponent extends Vue.with(
  ModifierItemDetailProps
) {
  context = setup(() => {
    const generateModifierList = (
      modifierList: Modifier[]
    ): ModifierElement[] => {
      return (
        modifierList.map((modifier) => {
          const modifierElement = modifier as ModifierElement
          return modifierElement
        }) ?? []
      )
    }

    const itemDetailState = reactive<ItemDetailStateInfo>({
      selectedModifierItemNode: null,
      modifierList: generateModifierList(this.$props?.item?.ModifierList ?? []),
      itemQuantity: 1
    })

    if ((this.$props.item?.ItemSelectedQuantity ?? 0) > 0) {
      itemDetailState.itemQuantity =
        this.$props.item?.ItemSelectedQuantity ?? 1
    }

    watch(
      () => this.$props.item?.ItemSelectedQuantity,
      () => {
        itemDetailState.itemQuantity =
          this.$props.item?.ItemSelectedQuantity ?? 1
        itemDetailState.itemQuantity =
          itemDetailState.itemQuantity === 0 ? 1 : itemDetailState.itemQuantity
      }
    )

    watch(
      () => this.$props.item?.ModifierList,
      () => {
        itemDetailState.modifierList = generateModifierList(
          this.$props?.item?.ModifierList ?? []
        )
      }
    )

    const showItemModifierView = computed<boolean>(
      () => itemDetailState.selectedModifierItemNode != null
    )

    const onItemModifiersOpen = (item: ModifierItem, modifier: Modifier) => {
      item.ModifierId = modifier.ModifierId
      itemDetailState.selectedModifierItemNode = item
    }

    const onItemModifiersClosed = () => {
      itemDetailState.selectedModifierItemNode = null
    }

    const onItemDetailClose = (item: ModifierItem) => {
      itemDetailState.selectedModifierItemNode = null
      this.$emit('item-detail-closed', item)
    }

    const onItemAdd = () => {
      const validModifierList = itemDetailState.modifierList.filter(
        (modifier) => modifier.modifierGroupRef?.context.modifierGroupIsValid
      )

      if (validModifierList.length === 0) {
        return Swal.fire({
          text: 'Debes verificar los modificadores obligatorios',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Cerrar',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }

      const modifierList = validModifierList.flatMap(
        (modifier): ModifierItemAddElement[] => {
          return (
            modifier.modifierGroupRef?.context.itemList
              .filter((item) => item.ItemIsSelected)
              .map<ModifierItemAddElement>((item) => {
                return {
                  ItemId: item.ItemId,
                  ItemName: item.ItemName,
                  ItemPrice: item.ItemPrice,
                  ItemQuantity: item.ItemSelectedQuantity,
                  ModifierId: modifier.ModifierId,
                  Modifiers: item.Modifiers
                }
              }) ?? []
          )
        }
      )

      this.$emit(
        'item-added',
        this.$props.item,
        itemDetailState.itemQuantity,
        modifierList
      )
    }

    const onItemQuantityChanged = (newValue: number) => {
      if (this.$props.item) {
        this.$emit('item-quantity-changed', this.item, newValue, this.modifier)
      }
    }

    const setItemQuantity = (quantity: number) => {
      itemDetailState.itemQuantity = quantity
    }

    return {
      ...toRefs(itemDetailState),
      showItemModifierView,

      onItemModifiersOpen,
      onItemModifiersClosed,
      onItemDetailClose,

      onItemAdd,
      onItemQuantityChanged,
      setItemQuantity
    }
  })
}
