import { Nullable } from '@/core/utils/CustomTypes'
import ClientList from '@/services/clients/ClientList'
import SerializableObject from '../core/SerializableObject'

class ClientSearchData extends SerializableObject {
    ClientList: Nullable<ClientList[]>

    constructor(clientList?: ClientList[]) {
        super()

        this.ClientList = clientList ?? null
    }
}

export default ClientSearchData