import { toDisplayString as _toDisplayString, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "header-menu align-items-stretch",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "header-menu",
  "data-kt-drawer-activate": "{default: true, lg: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_header_menu_mobile_toggle",
  "data-kt-place": "true",
  "data-kt-place-mode": "prepend",
  "data-kt-place-parent": "{default: '#kt_body', lg: '#kt_header_nav'}"
}
const _hoisted_2 = {
  class: "menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0",
  id: "#kt_header_menu",
  "data-kt-menu": "true"
}
const _hoisted_3 = { class: "menu-item me-lg-1" }
const _hoisted_4 = { class: "menu-item me-lg-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _withDirectives(_createVNode("div", { class: "menu-item me-lg-1 fs-2 text-gray-800 fw-light pe-10" }, _toDisplayString(_ctx.$route.meta?.header), 513), [
        [_vShow, _ctx.$route.meta?.header]
      ]),
      _createVNode("div", _hoisted_3, [
        (_ctx.$route.name === 'new-order')
          ? (_openBlock(), _createBlock("button", {
              key: 0,
              type: "button",
              class: "btn btn-sm btn-light-success",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearAll()))
            }, " Guardar y limpiar "))
          : _createCommentVNode("", true)
      ]),
      _createVNode("div", _hoisted_4, [
        (_ctx.$route.name === 'new-order')
          ? (_openBlock(), _createBlock("button", {
              key: 0,
              type: "button",
              class: "btn btn-sm btn-light-danger ms-2",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancelOrder()))
            }, " Cancelar "))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}