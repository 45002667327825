
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Actions } from '@/store/enums/StoreEnums'
import { User } from '@/services/auth/User'
import { Nullable } from '@/core/utils/CustomTypes'

export default defineComponent({
  name: 'kt-user-menu',
  components: {},
  setup () {
    const router = useRouter()
    const i18n = useI18n()
    const store = useStore()

    const user = computed<Nullable<User>>(() => store.getters.currentUser)

    i18n.locale.value = localStorage.getItem('lang')
      ? (localStorage.getItem('lang') as string)
      : 'es'

    const countries = {
      es: {
        flag: '/media/flags/spain.svg',
        name: 'Español'
      },
      en: {
        flag: '/media/flags/united-states.svg',
        name: 'English'
      }
    }

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => {
          store
            .dispatch(Actions.CART_CLEAR_ALL)

          router.push('/login')
        })
    }

    const setLang = lang => {
      localStorage.setItem('lang', lang)
      i18n.locale.value = lang
    }

    const currentLanguage = lang => {
      return i18n.locale.value === lang
    }

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value]
    })

    return {
      user,
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries
    }
  }
})
