
import { ref, reactive, toRefs, onMounted } from 'vue'
import { Options, Vue, setup } from 'vue-class-component'

import {
  ErrorMessage,
  Field,
  Form,
  PublicFormContext
} from 'vee-validate'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import * as Yup from 'yup'

import KTLoader from '@/components/Loader.vue'

import AllSectionsService from '@/services/allsections/allsections.service'
import AppWorkspaceService from '@/services/workspaces/app.service'

import { AllSections } from '@/services/allsections/AllSections'
import AppWorkspaceData from '@/services/workspaces/AppWorkspaceData'
import AppWorkspace from '@/services/workspaces/AppWorkspace'

import { Actions } from '@/store/enums/StoreEnums'

import { loaderEnabled, loaderLogo } from '@/core/helpers/config'
import { Nullable } from '@/core/utils/CustomTypes'

enum LoginStep {
  Login = 'LOGIN',
  Workspaces = 'WORKSPACE',
  Password = 'PASSWORD'
}

@Options({
  name: 'Login',
  components: {
    Field,
    Form,
    ErrorMessage,
    KTLoader
  }
})
export default class LoginComponent extends Vue {
  context = setup(() => {
    const store = useStore()
    const router = useRouter()

    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading')

    onMounted(() => {
      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      }, 50)
    })

    const loginState = reactive({
      step: LoginStep.Login,
      workspaces: [] as AppWorkspace[],
      selectedWorkspace: null as Nullable<AppWorkspace>,

      loginId: null as Nullable<string>,
      password: null as Nullable<string>
    })

    const submitButton = ref<Nullable<HTMLElement>>(null)
    const passwordForm = ref<Nullable<PublicFormContext>>(null)
    const submitPasswordButton = ref<Nullable<HTMLElement>>(null)

    // Create form validation object
    const loginValidation = Yup.object().shape({
      loginId: Yup.string().required().label('Id de acceso')
    })

    const passwordValidation = Yup.object().shape({
      password: Yup.string().required().label('Contraseña')
    })

    AllSectionsService.get()
      .then((allSections: AllSections) => console.log(allSections.getAppData()))
      .catch(() => {
        // ignored
      })

    // Form submit function
    const onSubmitLogin = (values) => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute('data-kt-indicator', 'on')

        AppWorkspaceService.getAppListForLogin(values.loginId)
          .then((data: AppWorkspaceData) => {
            if (!(data.AppList?.length > 0)) {
              submitButton.value?.removeAttribute('data-kt-indicator')

              return Swal.fire({
                text: 'No cuenta con acceso a la plataforma',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Cerrar',
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger'
                }
              })
            }

            loginState.loginId = values.loginId
            loginState.workspaces = data.AppList

            if (loginState.workspaces.length > 1) {
              loginState.step = LoginStep.Workspaces
            } else {
              onWorkspaceSelected(loginState.workspaces[0])
            }

            submitButton.value?.removeAttribute('data-kt-indicator')
          })
          .catch((error: Error) => {
            Swal.fire({
              text: error.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Cerrar',
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger'
              }
            })

            submitButton.value?.removeAttribute('data-kt-indicator')
          })
      }
    }

    const onWorkspaceSelected = (appWorkspace: AppWorkspace) => {
      loginState.step = LoginStep.Password
      loginState.selectedWorkspace = appWorkspace

      passwordForm.value?.resetForm()
    }

    const onPasswordSubmit = (values) => {
      submitPasswordButton.value?.setAttribute('data-kt-indicator', 'on')

      const workspace = loginState.selectedWorkspace ?? new AppWorkspace()
      const user = {
        loginId: loginState.loginId ?? '',
        password: values.password
      }

      store
        .dispatch(Actions.LOGIN, { workspace, user })
        .then(() => {
          store.dispatch(Actions.SELECT_WORKSPACE, workspace)
          router.push('/')
          // submitPasswordButton.value?.removeAttribute('data-kt-indicator')
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })

          submitPasswordButton.value?.removeAttribute('data-kt-indicator')
        })
    }

    const onBackForm = (step: LoginStep) => {
      if (
        step === LoginStep.Workspaces &&
        !(loginState.workspaces.length > 1)
      ) {
        loginState.step = LoginStep.Login
      } else {
        loginState.step = step
      }

      passwordForm.value?.resetForm()
    }

    return {
      loginValidation,
      submitButton,

      passwordValidation,
      submitPasswordButton,

      ...toRefs(loginState),

      onSubmitLogin,
      onWorkspaceSelected,

      passwordForm,
      onPasswordSubmit,

      onBackForm,

      LoginStep,

      loaderEnabled,
      loaderLogo
    }
  })
}
