import { Nullable } from '@/core/utils/CustomTypes'
import Chat from '@/services/chat/Chat'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations } from '../enums/StoreEnums'

export enum ServiceType {
  Phone = 'phone',
  Chat = 'chat'
}

export interface ServiceTypeStateInfo {
  serviceType: ServiceType
  serviceTypeCount: { [type: string] : number }
  chat: Nullable<Chat>,
}

@Module
export default class ServiceTypeModule extends VuexModule
  implements ServiceTypeStateInfo {
  serviceType: ServiceType = ServiceType.Chat
  serviceTypeCount = {};
  chat: Nullable<Chat> = null

  get currentServiceType (): ServiceType {
    return this.serviceType
  }

  get currentChat (): Nullable<Chat> {
    return this.chat
  }

  get currentChatCount () : number {
    return this.serviceTypeCount?.[ServiceType.Chat] ?? 0
  }

  get currentPhoneCount () : number {
    return this.serviceTypeCount[ServiceType.Phone] ?? 0
  }

  @Mutation
  [Mutations.SET_SERVICE_TYPE] (serviceType: ServiceType): void {
    this.serviceType = serviceType ?? ServiceType.Phone
  }

  @Mutation
  [Mutations.SET_CHAT] (chat: Chat): void {
    this.chat = chat
  }

  @Mutation
  [Mutations.SET_SERVICE_TYPE_COUNT] ({ type, count } : { type: ServiceType, count: number }) : void {
    this.serviceTypeCount[type] = count ?? 0
  }

  @Action
  [Actions.SET_SERVICE_PHONE] (): void {
    this.context.commit(Mutations.SET_SERVICE_TYPE, ServiceType.Phone)
  }

  @Action
  [Actions.SET_SERVICE_CHAT] (): void {
    this.context.commit(Mutations.SET_SERVICE_TYPE, ServiceType.Chat)
  }

  @Action
  [Actions.SET_SERVICE_TYPE] (serviceType: ServiceType): void {
    this.context.commit(Mutations.SET_SERVICE_TYPE, serviceType)
  }

  @Action
  [Actions.SET_CURRENT_CHAT] (chat: Chat) : void {
    this.context.commit(Mutations.SET_CHAT, chat)
  }

  @Action
  [Actions.CLEAR_CURRENT_CHAT] (chat: Chat) : void {
    this.context.commit(Mutations.SET_CHAT, chat)
  }

  @Action
  [Actions.SET_CHAT_COUNT] (count: number) : void {
    this.context.commit(Mutations.SET_SERVICE_TYPE_COUNT, {
      type: ServiceType.Chat,
      count
    })
  }

  @Action
  [Actions.SET_PHONE_COUNT] (count: number) : void {
    this.context.commit(Mutations.SET_SERVICE_TYPE_COUNT, {
      type: ServiceType.Chat,
      count
    })
  }
}
