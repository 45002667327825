
import { defineComponent, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import KTAside from '@/layout/aside/Aside.vue'
import KTHeader from '@/layout/header/Header.vue'
import HtmlClass from '@/core/services/LayoutService'
import KTScrollTop from '@/layout/extras/ScrollTop.vue'
import KTLoader from '@/components/Loader.vue'
import KTCreateApp from '@/components/modals/wizards/CreateAppModal.vue'
import KTDrawerMessenger from '@/layout/extras/DrawerMessenger.vue'
import { Actions } from '@/store/enums/StoreEnums'
import { MenuComponent } from '@/assets/ts/components/index'
import { removeModalBackdrop } from '@/core/helpers/dom'
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo
} from '@/core/helpers/config'
import { isDocPage } from '@/core/helpers/documentation'
import CallPopupComponent from '@/components/call/CallPopup.vue'

export default defineComponent({
  name: 'Layout',
  components: {
    KTAside,
    KTHeader,
    KTScrollTop,
    KTCreateApp,
    KTDrawerMessenger,
    KTLoader,
    CallPopupComponent
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading')

    // initialize html element classes
    HtmlClass.init()

    const pageTitle = computed(() => {
      return store.getters.pageTitle
    })

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath
    })

    onMounted(() => {
      // // check if current user is authenticated
      if (!store.getters.isAuthenticated) {
        router.push({ name: 'login' })
      }

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      }, 500)
    })

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined)
        setTimeout(() => {
          MenuComponent.reinitialization()
        }, 1)

        // // check if current user is authenticated
        if (!store.getters.isAuthenticated) {
          router.push({ name: 'login' })
        }

        removeModalBackdrop()
      }
    )

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      isDocPage,
      themeLightLogo,
      themeDarkLogo
    }
  }
})
