import { createApp } from 'vue'
import App from './App.vue'

import store from './store'
import emitter from '@/utils/Emitter'
import firebaseMessaging from '@/utils/Firebase'

import router from './router'

import ElementPlus from 'element-plus'
import i18n from '@/core/plugins/i18n'

// import MockAdapter from '@/core/mock/MockService'
// import ApiService from '@/core/services/ApiService'
import { initApexCharts } from '@/core/plugins/apexcharts'
import { initInlineSvg } from '@/core/plugins/inline-svg'
import { initVeeValidate } from '@/core/plugins/vee-validate'

import MenuGroupListItem from '@/components/menu/ModifierGroupListItem.vue'

import '@/core/plugins/keenthemes'
import '@/core/plugins/prismjs'
import 'bootstrap'

import { es } from 'yup-locales'
import { setLocale } from 'yup'

setLocale(es)

const app = createApp(App)

app.use(store)
app.use(router, app)
app.use(ElementPlus)

// ApiService.init(app)
// MockAdapter.init(app)
initApexCharts(app)
initInlineSvg(app)
initVeeValidate()

app.use(i18n)

app.provide('emitter', emitter)
app.provide('messaging', firebaseMessaging)

app.component('MenuGroupListItem', MenuGroupListItem)

app.mount('#app')
