import Branch from '../branches/Branch'
import SerializableObject from '../core/SerializableObject'
import {
  DivisionGroup,
  Modifier,
  ProductPromotionType
} from '../menu/MenuData'
import PaymentType from './PaymentType'

export default class Cart extends SerializableObject {
  OrderId?: number

  BranchId?: number
  BranchName?: string

  OrderSubTotal?: number
  OrderChargeFee?: number
  OrderDiscount?: number
  OrderTotal?: number
  OrderTip?: number

  OrderLatitude?: number
  OrderLongitude?: number

  OrderIsPickUp = false
  OrderIsScheduled = false

  AddressId?: number

  Branch?: Branch
  ProductList: CartProduct[] = []
  SuggestedProductList: CartSuggestedProduct[] = []

  PaymentTypeList: PaymentType[] = []
}

export interface CartProduct {
  OrderProductId: number
  ProductId: number
  ProductExternalId: string

  ProductName: string
  ProductImage: string
  ProductComments?: string

  ProductPrice: number
  ProductQuantity: number
  ProductPromotionQuantity: number

  ProductTotal: number
  ProductFinal: number

  ProductIsCoupon: boolean
  ProductWeight?: number
  ProductVolume?: number

  PromotionType?: ProductPromotionType

  DivisionGroup: DivisionGroup
  ModifierList: CartProductItem[]
}

export interface CartProductItem {
  OrderProductItemId: number

  ItemId: number
  ItemExternalId: number

  ModifierId: number
  ModifierExternalId: string

  ModifierName: string
  ItemName: string

  ItemPrice: number
  ItemQuantity: number

  OrderProductItemParentId?: number
  ItemParentId?: number

  Modifiers?: CartProductItem[]
}

export interface CartSuggestedProduct {
  ProductId: number

  ProductName: string
  ProductImage: string
  ProductDescription?: string

  ProductPrice: number
  ProductIsAvailable: boolean

  ProductIsExternalAvailable: number
  ModifierList: Modifier[]
}
