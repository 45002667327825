import { Services } from '@/store/enums/StoreEnums'
import authAxios from '@/services/core/AuthAxios'
import { AllSections } from '../allsections/AllSections'
import allsectionsService from '../allsections/allsections.service'
import AppWorkspace from '../workspaces/AppWorkspace'
import ServiceHandler from '../core/ServiceHandler'
import store from '@/store'
import CallSourceListData from './CallSourceData'

class CallService {
  getActiveSourceList () {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(
        Services.CALL_ACTIVE_SOURCE_LIST,
        workspace
      )

      return authAxios
        .post(serviceUrl, {})
        .then(
          ServiceHandler.checkSuccess<CallSourceListData>(CallSourceListData)
        )
        .catch(ServiceHandler.catchError())
    })
  }
}

export const callService = new CallService()
