enum Actions {
  // action types
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  UPDATE_USER = 'updateUser',
  FORGOT_PASSWORD = 'forgotPassword',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  SET_CART_INFO = 'setCartInfo',

  SELECT_WORKSPACE = 'selectCurrentWorkspace',
  CLEAR_WORKSPACE = 'clearCurrentWorkspace',

  UPDATE_DELIVERY_CONFIG = 'updateCartDeliveryConfig',
  UPDATE_PICKUP_CONFIG = 'updateCartPickUpConfig',
  UPDATE_SCHEDULE_DATE = 'updateCartScheduleDate',
  UPDATE_CART = 'updateCartObject',
  UPDATE_CART_TOTALS = 'updateCartTotals',

  CART_EMPTY = 'cart_empty_product',
  CART_CLEAR_ALL = 'cart_clear_all',

  SET_SERVICE_PHONE = 'setServicePhone',
  SET_SERVICE_CHAT = 'setServiceChat',
  SET_SERVICE_TYPE = 'setServiceTypeAction',

  SHOW_MODAL = 'showModalAction',
  HIDE_ALL_MODALS = 'hideAllModals',

  SET_CURRENT_CHAT = 'setCurrentChat',
  CLEAR_CURRENT_CHAT = 'clearCurrentChat',

  SET_CHAT_COUNT = 'setChatCount',
  SET_PHONE_COUNT = 'setPhoneCount',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',
  SET_PASSWORD = 'setPassword',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',

  SET_WORKSPACE = 'setAppWorkspace',
  PURGE_WORKSPACE = 'purgeAppWorkspace',

  SET_CART_BRANCH = 'setCartBranch',
  SET_ORDER_DATE = 'setOrderDate',
  SET_ORDER_IS_PICKUP = 'setOrderIsPickUp',
  SET_CART_ADDRESS = 'setCartAddress',
  SET_CART_CLIENT = 'setCartClient',
  SET_CART = 'setCart',
  SET_CART_TOTALS = 'setCartTotals',

  SET_SERVICE_TYPE = 'setServiceType',
  SET_MODAL_ID = 'setModalId',

  SET_CHAT = 'setChat',
  SET_SERVICE_TYPE_COUNT = 'setServiceTypeCount'
}

enum Services {
  GET_WORKSPACES = 'callcenter_get_workspaces',
  LOGIN_USER = 'client_login_user',
  LOGOUT_USER = 'Logout',
  VERIFY_USER = 'user_verify_login',
  CLIENT_SEARCH = 'client_search',
  CLIENT_LIST_BY_PHONE = 'client_list_for_phone',
  CLIENT_ADD = 'client_add_callcenter',
  CLIENT_ADD_ADDRESS = 'client_add_address',
  SEND_BRANCH_INFO = 'send_branch_info',
  GET_BRANCH_LIST = 'get_branch_list',
  GET_BRANCH_LOCATION = 'get_branch_for_location',
  GET_BRANCH_PICKUP = 'get_pickup_branch_list',
  GET_BRANCH_SCHEDULE = 'get_branch_schedule',
  GET_MENU_FULL = 'get_menu_full',
  GET_MENU_FULL_GROUPS = 'get_menu_full_groups',
  GET_MENU_PRODUCT = 'client_get_product',
  CART_ADD_PRODUCT = 'client_add_product_cart',
  CART_ADD_COUPON = 'client_add_coupon',
  CART_UPDATE_PRODUCT = 'client_update_product_cart',
  CART_REMOVE_PRODUCT = 'client_delete_product_cart',
  CART_REMOVE_PRODUCT_LIST = 'client_delete_product_cart_list',
  CART_GET = 'client_get_cart',
  CART_GET_TOTALS = 'client_get_cart_totals',
  CART_PLACE_VALIDATION = 'client_get_placeValidation',
  CART_PLACE = 'client_place_order',
  CART_PLACE_GUEST = 'client_place_order_guest',
  CART_PAYMENT_LINK = 'client_get_webpay_link',
  CART_SEND_PAYMENT_LINK = 'client_send_webpay_link',
  CART_PAYMENT_STATUS = 'client_get_orderpayment_status',
  CART_SET_INFO = 'client_set_cartInfo',

  ORDER_ACTIVE_LIST = 'client_list_active_orders',
  ORDER_HISTORY_LIST = 'client_list_history_orders',
  SEND_ORDER_INFO = 'send_order_info',

  CHAT_LIST = 'client_chat_list_type',
  CHAT_MESSAGE_LIST = 'client_chat_message_list',
  CHAT_MESSAGE_SEND = 'client_chat_send_message',
  CHAT_SET_READ = 'client_chat_set_read',

  CALL_ACTIVE_SOURCE_LIST = 'call_active_sources',
}

enum Constans {
  APP_VERSION = 25,
  ALLSECTIONS_MAX_MINUTES = 1440, // 24 Hours
  AUTH_MAX_MINUTES = 30,
  APP_GOOGLEMAPS_KEY = 'AIzaSyCMbWpZYzR4ZKyVvrKU5zdBrhMrsjchqCA'
}

export { Actions, Mutations, Services, Constans }
