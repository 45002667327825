import { Nullable } from "@/core/utils/CustomTypes";
import SerializableObject from "../core/SerializableObject";
import Address from "./Address";

export default class AddressData extends SerializableObject {
    Address: Nullable<Address>

    constructor(address?: Address) {
        super()
        this.Address = address ?? null
    }
}