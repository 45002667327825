import axios from 'axios'
import authAxios from '@/services/core/AuthAxios'
import { AllSections } from '../allsections/AllSections'
import allsectionsService from '../allsections/allsections.service'
import ServiceHandler from '../core/ServiceHandler'
import AppWorkspace from '../workspaces/AppWorkspace'
import UserLoginData from './UserLoginData'
import { Services } from '@/store/enums/StoreEnums'

class AuthService {
  workspace: AppWorkspace

  constructor (workspace: AppWorkspace) {
    this.workspace = workspace
  }

  login (data: { loginId: string; password: string }) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const serviceUrl = allSections.getServiceUrl(
        Services.LOGIN_USER,
        this.workspace
      )

      return axios
        .post('/Authentication/Authentication/Login', {
          target: serviceUrl,
          // hashIsEnabled: this.workspace.AppHashIsEnabled,
          hashIsEnabled: true,
          data: {
            CredentialLogin: data.loginId,
            CredentialPwd: data.password
          }
        })
        .then(ServiceHandler.checkSuccess<UserLoginData>(UserLoginData))
        .catch(ServiceHandler.catchError())
    })
  }

  verifyLogin (token?: string) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const serviceUrl = allSections.getServiceUrl(
        Services.VERIFY_USER,
        this.workspace
      )

      return authAxios
        .post(serviceUrl, {
          Data: {
            ClientToken: token
              ? {
                ClientTokenPlatform: 'web',
                ClientTokenCode: token
              }
              : null
          }
        })
        .then(ServiceHandler.checkSuccess<UserLoginData>(UserLoginData))
        .catch(ServiceHandler.catchError())
    })
  }
}

export default AuthService
