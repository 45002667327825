import { Nullable } from '@/core/utils/CustomTypes'
import Branch from '@/services/branches/Branch'
import { BranchScheduleInterval } from '@/services/branches/BranchScheduleData'
import Cart from '@/services/cart/Cart'
import cartService from '@/services/cart/cart.service'
import CartResponseData from '@/services/cart/CartResponseData'
import Address from '@/services/clients/Address'
import ClientList from '@/services/clients/ClientList'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations } from '../enums/StoreEnums'

export interface CartDeliveryConfig {
  Branch: Nullable<Branch>
  Address: Nullable<Address>
  OrderScheduleInterval: Nullable<BranchScheduleInterval>
  Client: Nullable<ClientList>
}

export interface CartPickUpConfig {
  Branch: Nullable<Branch>
  OrderScheduleInterval: Nullable<BranchScheduleInterval>
  Client: Nullable<ClientList>
}

export interface CartConfigInfo {
  Branch: Nullable<Branch>
  OrderDate: Nullable<string>
  OrderScheduleInterval: Nullable<BranchScheduleInterval>
  OrderIsPickUp: boolean,
  Location: Nullable<{ Latitude: number, Longitude: number }>
  Address: Nullable<Address>
  Client: Nullable<ClientList>
}

export interface CartStateInfo {
  cartConfig: CartConfigInfo
  cart: Nullable<Cart>
}

@Module
export default class CartModule extends VuexModule implements CartStateInfo {
  cartConfig: CartConfigInfo = {
    Branch: null,
    OrderDate: null,
    OrderScheduleInterval: null,
    OrderIsPickUp: false,
    Location: null,
    Address: null,
    Client: null
  }

  cart: Nullable<Cart> = null

  get currentConfig () : CartConfigInfo {
    return this.cartConfig
  }

  get currentCart () : Nullable<Cart> {
    return this.cart
  }

  @Mutation
  [Mutations.SET_CART_CLIENT] (client: Nullable<ClientList>) : void {
    this.cartConfig.Client = client
  }

  @Mutation
  [Mutations.SET_CART_BRANCH] (branch: Nullable<Branch>) : void {
    this.cartConfig.Branch = branch
  }

  @Mutation
  [Mutations.SET_ORDER_DATE] (orderScheduleInterval: BranchScheduleInterval) : void {
    this.cartConfig.OrderScheduleInterval = orderScheduleInterval
    this.cartConfig.OrderDate = orderScheduleInterval?.BranchScheduleIntervalStart ?? null
  }

  @Mutation
  [Mutations.SET_ORDER_IS_PICKUP] (isPickUp: boolean) : void {
    this.cartConfig.OrderIsPickUp = isPickUp
  }

  @Mutation
  [Mutations.SET_CART_ADDRESS] (address: Nullable<Address>) : void {
    this.cartConfig.Address = address
    this.cartConfig.Location = address?.AddressLat && address?.AddressLng
      ? {
        Latitude: address.AddressLat,
        Longitude: address.AddressLng
      }
      : null
  }

  @Mutation
  [Mutations.SET_CART] (cart: Nullable<Cart>) : void {
    this.cart = cart
  }

  @Mutation
  [Mutations.SET_CART_TOTALS] (cart: Nullable<Cart>) : void {
    if (!this.cart || !cart) {
      return
    }

    this.cart.OrderSubTotal = cart.OrderSubTotal
    this.cart.OrderTotal = cart.OrderTotal
    this.cart.OrderDiscount = cart.OrderDiscount
    this.cart.OrderChargeFee = cart.OrderChargeFee
  }

  @Action
  [Actions.UPDATE_DELIVERY_CONFIG] (config: CartDeliveryConfig) : void {
    this.context.commit(Mutations.SET_CART_BRANCH, config.Branch)
    this.context.commit(Mutations.SET_ORDER_DATE, config.OrderScheduleInterval)
    this.context.commit(Mutations.SET_CART_ADDRESS, config.Address)
    this.context.commit(Mutations.SET_ORDER_IS_PICKUP, false)
    this.context.commit(Mutations.SET_CART_CLIENT, config.Client)

    this.context.dispatch(Actions.SET_CART_INFO)
  }

  @Action
  [Actions.UPDATE_PICKUP_CONFIG] (config: CartPickUpConfig) : void {
    this.context.commit(Mutations.SET_CART_BRANCH, config.Branch)
    this.context.commit(Mutations.SET_ORDER_DATE, config.OrderScheduleInterval)
    this.context.commit(Mutations.SET_CART_ADDRESS, null)
    this.context.commit(Mutations.SET_ORDER_IS_PICKUP, true)
    this.context.commit(Mutations.SET_CART_CLIENT, config.Client)

    this.context.dispatch(Actions.SET_CART_INFO)
  }

  @Action
  [Actions.UPDATE_SCHEDULE_DATE] (orderScheduleInterval: BranchScheduleInterval) : void {
    this.context.commit(Mutations.SET_ORDER_DATE, orderScheduleInterval)
  }

  @Action
  [Actions.UPDATE_CART] () : Promise<Nullable<Cart> | void> {
    if (!this.cartConfig) {
      this.context.commit(Mutations.SET_CART, null)
      return Promise.resolve(null)
    }

    return cartService.getCart(this.cartConfig)
      .then((data: CartResponseData) => {
        const cart = data.Cart ? new Cart().from(data.Cart) : null
        if (cart) {
          cart.PaymentTypeList = data.PaymentTypeList ?? []
        }

        this.context.commit(Mutations.SET_CART, cart)
        this.context.dispatch(Actions.UPDATE_CART_TOTALS, { paymentIsOnline: false, usePoins: false })
        return cart
      })
      .catch(() => {
        // ignored
      })
  }

  @Action
  [Actions.UPDATE_CART_TOTALS] (params: { paymentIsOnline?: boolean, usePoins?: boolean }) : void {
    if (!this.cartConfig) {
      this.context.commit(Mutations.SET_CART, null)
      return
    }

    cartService.getCartTotals(params.paymentIsOnline ?? false, this.cartConfig)
      .then((data: CartResponseData) => {
        const cart = data.Cart ? new Cart().from(data.Cart) : null
        this.context.commit(Mutations.SET_CART_TOTALS, cart)
      })
      .catch(() => {
        // ignored
      })
  }

  @Action
  [Actions.SET_CART_INFO] () : Promise<Nullable<Cart> | void> {
    if (!this.cartConfig) {
      this.context.commit(Mutations.SET_CART, null)
      return Promise.resolve(null)
    }

    return cartService.setCartInfo(this.cartConfig)
      .then(() => {
        // ignored
      })
      .catch(() => {
        // ignored
      })
  }

  @Action
  [Actions.CART_EMPTY] () : Promise<Nullable<Cart> | void> {
    if (!this.cartConfig || !this.cart) {
      this.context.commit(Mutations.SET_CART, null)
      return Promise.resolve(null)
    }

    const productList = this.cart.ProductList
      .map(product => product.OrderProductId)

    return cartService.removeProductList(productList, this.cartConfig.Client)
      .then(() => {
        this.context.dispatch(Actions.UPDATE_CART)
      })
  }

  @Action
  [Actions.CART_CLEAR_ALL] () : void {
    this.context.commit(Mutations.SET_CART, null)

    this.context.commit(Mutations.SET_CART_BRANCH, null)
    this.context.commit(Mutations.SET_ORDER_DATE, null)
    this.context.commit(Mutations.SET_CART_ADDRESS, null)
    this.context.commit(Mutations.SET_ORDER_IS_PICKUP, false)
    this.context.commit(Mutations.SET_CART_CLIENT, null)
  }
}
