import SerializableObject from "@/services/core/SerializableObject"
import AppWorkspace from "../workspaces/AppWorkspace"
import { Constans } from '@/store/enums/StoreEnums'
import { Nullable } from "@/core/utils/CustomTypes"

class AllSections extends SerializableObject {
    appVersion: Nullable<number> = null
    timestamp: Nullable<number> = null
    
    appData: AppData[] = []
    services: AppService[] = []

    getAppData() : AppData {
        if (!(this.appData?.length ?? 0 > 0)) {
            return new AppData()
        }

        return this.appData[0]
    }

    getService(serviceKey: string) : Nullable<AppService> {
        if (!serviceKey) {
            return null
        }

        const service = this.services.find((service) => service.type === serviceKey)
        return service ?? null
    }

    getServiceUrl(serviceKey: string, workspace?: Nullable<AppWorkspace>) : string {
        const service = this.getService(serviceKey)
        return `${workspace?.AppServiceUrl ?? ''}${service?.content ?? ''}`
    }

    isValid() : boolean {
        const isInTime = !!this.timestamp && (new Date().getTime() - this.timestamp) <= Constans.ALLSECTIONS_MAX_MINUTES * 60_000
        return Constans.APP_VERSION === this.appVersion && isInTime
                
    }
}

class AppData {
    aboutApp: string = ''
    aboutCredits: string = ''
    aboutPrivacy: string = ''
    aboutTerms: string = ''
    aboutTr3sco: string = ''
}

class AppService {
    type: string
    content: string

    constructor(type: string, content: string) {
        this.type = type
        this.content = content
    }
}

//export default AllSections
export { AllSections, AppData, AppService }
