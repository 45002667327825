import { Nullable } from '@/core/utils/CustomTypes'
import ClientList from '@/services/clients/ClientList'

interface CallModelData {
  id: string
  from: string
  callProvider: CallProvider
  metadata: any
  onAnswer: (metadata: any) => void
  onHangUp: (metadata: any) => void
  onToggleMute: (metadata: any) => void
}

export enum CallState {
  Ringing = 'ringin',
  Active = 'active',
  Ended = 'ended'
}

export class CallModel {
  id: string
  from: string
  callProvider: CallProvider
  metadata: any
  state = CallState.Ringing
  startDate: Nullable<Date>
  isMuted = false
  client?: Nullable<ClientList>

  protected onAnswer: (call: CallModel) => void
  protected onHangUp: (call: CallModel) => void
  protected onToggleMute: (call: CallModel) => void

  constructor (data: CallModelData) {
    this.id = data.id
    this.from = data.from
    this.callProvider = data.callProvider
    this.metadata = data.metadata

    this.onAnswer = data.onAnswer
    this.onHangUp = data.onHangUp
    this.onToggleMute = data.onToggleMute
  }

  public answer (): void {
    this.onAnswer?.(this)
    this.state = CallState.Active
    this.startDate = new Date()
  }

  public hangUp (): void {
    this.onHangUp?.(this)
    this.state = CallState.Ended
  }

  public toggleMute () : void {
    this.onToggleMute?.(this)
    this.isMuted = !this.isMuted
  }
}

export abstract class CallProviderUtil {
  protected callProvider: CallProvider
  protected callReceived?: (call: CallModel) => void
  protected callEnded?: (call: CallModel) => void
  protected callAnswered?: (call: CallModel) => void

  constructor (callProvider: CallProvider) {
    this.callProvider = callProvider
  }

  abstract init (): void

  public onCallReceived (callback: (call: CallModel) => void): void {
    this.callReceived = callback
  }

  public onCallEnded (callback: (call: CallModel) => void): void {
    this.callEnded = callback
  }

  public onCallAnswered (callback: (call: CallModel) => void): void {
    this.callAnswered = callback
  }
}
