import { Nullable } from "@/core/utils/CustomTypes"
import SerializableObject from "../core/SerializableObject"
import AppWorkspace from "./AppWorkspace"


class AppWorkspaceData extends SerializableObject {
    AppList: AppWorkspace[]

    constructor(appList: Nullable<AppWorkspace[]> = null) {
        super()
        this.AppList = appList ?? <AppWorkspace[]>[]
    }
}

export default AppWorkspaceData
