import { Nullable } from '@/core/utils/CustomTypes'
import { Channel } from './Chat'

export enum MessageType {
  Image = 'image',
  Pdf = 'pdf',
  Audio = 'audio',
  Video = 'video',
  Location = 'location'
}

export interface MessageStatus {
  MessageStatusId: Nullable<string>
  MessageStatusName: Nullable<string>
  MessageStatusColor: Nullable<string>
}

export default interface Message {
  MessageId: Nullable<number>
  MessageCreateDate: Nullable<string>
  MessageUpdateDate: Nullable<string>
  MessageTypeId: Nullable<string>
  MessageFromName: Nullable<string>
  MessageFromNumber: Nullable<string>
  MessageBody: Nullable<string>
  MessageType: Nullable<string>
  MessageAttachmentUrl: Nullable<string>
  MessageAttachmentContentType: Nullable<string>
  MessageLatitude: Nullable<number>
  MessageLongitude: Nullable<number>
  MessageIsUnsupported: Nullable<boolean>
  Channel: Nullable<Channel>
  MessageStatus: Nullable<MessageStatus>
}
