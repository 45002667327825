class SerializableObject {
    from(source: object) {
        if (!source) {
            return this
        }
        
        Object.assign(this, source)
        return this
    }
}

export default SerializableObject
