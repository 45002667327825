import { Actions, Constans, Mutations } from '@/store/enums/StoreEnums'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'

import AuthService from '@/services/auth/auth.service'

import UserLoginData from '@/services/auth/UserLoginData'
import { User } from '@/services/auth/User'
import AppWorkspace from '@/services/workspaces/AppWorkspace'
import router from '@/router'
import { Nullable } from '@/core/utils/CustomTypes'

import messaging from '@/utils/Firebase'
import { getToken } from 'firebase/messaging'

export interface UserAuthInfo {
  user: Nullable<User>
  lastCheck: Nullable<number>
}

const VKey = 'BNCofArfXjvzY7RWt5jxK9gp81qyNIIBg7ZPYqQhfL8RX7_-R24B_-ewz7zyWcYZmaJmPpnqmyvqeZ_XhHpba4A'

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  user = ((): Nullable<User> => {
    const userJson = JSON.parse(localStorage.getItem('user') ?? 'null')
    const initialUser = userJson ? new User().from(userJson) : null

    return initialUser
  })()

  lastCheck = ((): Nullable<number> => {
    const lastCheck = localStorage.getItem('lastcheck') ?? null
    return lastCheck ? +lastCheck : null
  })()

  get currentUser (): User {
    return new User().from(this.user ?? new User())
  }

  get isAuthenticated (): boolean {
    return !!this.user
  }

  @Mutation
  [Mutations.SET_AUTH] (user: User) : void {
    this.user = user
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('lastcheck', new Date().getTime().toString())
    // JwtService.saveToken(this.user.token)
  }

  @Mutation
  [Mutations.SET_USER] (user: User) : void {
    this.user = user
    localStorage.setItem('user', JSON.stringify(user))
  }

  @Mutation
  [Mutations.PURGE_AUTH] () : void {
    this.user = null
    localStorage.removeItem('user')
    localStorage.removeItem('lastcheck')
    // JwtService.destroyToken()
  }

  @Action({ rawError: true })
  [Actions.LOGIN] ({
    workspace,
    user
  }: {
    workspace: AppWorkspace
    user: { loginId: string; password: string }
  }) {
    const authService = new AuthService(workspace)
    return authService
      .login(user)
      .then((data: UserLoginData) => {
        this.context.commit(Mutations.SET_AUTH, data.User)
        return Promise.resolve(data.User)
      })
      .catch((error: Error) => {
        this.context.commit(Mutations.SET_ERROR)
        return Promise.reject(error)
      })
  }

  @Action
  [Actions.LOGOUT] () : void {
    this.context.commit(Mutations.PURGE_AUTH)
    this.context.commit(Mutations.PURGE_WORKSPACE, { root: true })
  }

  @Action
  [Actions.VERIFY_AUTH] () {
    const workspace = this.context.rootState.WorkspaceModule.workspace
    if (this.isAuthenticated && workspace) {
      const isInTime =
        !!this.lastCheck &&
        new Date().getTime() - this.lastCheck <=
          Constans.AUTH_MAX_MINUTES * 60_000

      if (isInTime) {
        getToken(messaging, { vapidKey: VKey })
        return
      }

      const executeAuth = (workspace: AppWorkspace, token?: string) => {
        const authService = new AuthService(workspace)
        return authService
          .verifyLogin(token)
          .then((data: UserLoginData) => {
            this.context.commit(Mutations.SET_AUTH, this.user)
            return Promise.resolve(data.User)
          })
          .catch(() => {
            this.context.commit(Mutations.PURGE_AUTH)
            this.context.commit(Mutations.PURGE_WORKSPACE, { root: true })

            router.push('/login')
          })
      }

      getToken(messaging, { vapidKey: VKey })
        .then(currentToken => {
          executeAuth(workspace, currentToken)
        })
        .catch(() => {
          executeAuth(workspace)
        })

      if (Notification.permission !== 'granted') {
        return executeAuth(workspace)
      }
    } else {
      this.context.commit(Mutations.PURGE_AUTH)
      this.context.commit(Mutations.PURGE_WORKSPACE, { root: true })
    }
  }
}
