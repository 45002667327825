import { createStore } from 'vuex'

import AuthModule from '@/store/modules/AuthModule'
import BodyModule from '@/store/modules/BodyModule'
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule'
import ConfigModule from '@/store/modules/ConfigModule'
import WorkspaceModule from '@/store/modules/WorkspaceModule'
import CartModule from '@/store/modules/CartModule'
import ServiceTypeModule from '@/store/modules/ServiceTypeModule'
import ModalModule from '@/store/modules/ModalModule'

export default createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    WorkspaceModule,
    CartModule,
    ServiceTypeModule,
    ModalModule
  }
})
