import authAxios from '@/services/core/AuthAxios'
import allsectionsService from '@/services/allsections/allsections.service'
import { AllSections } from '../allsections/AllSections'
import ServiceResponse from '../core/ServiceResponse'
import AppWorkspaceData from './AppWorkspaceData'
import ServiceHandler from '../core/ServiceHandler'
import { Services } from '@/store/enums/StoreEnums'

class AppWorkspaceService {
    getAppListForLogin(userLoginId: string) {
        return allsectionsService.get().then((allsections : AllSections) => {
            const service = allsections.getService(Services.GET_WORKSPACES)
            return authAxios
                .post<ServiceResponse<AppWorkspaceData>>(service?.content ?? '', {
                    Data: {
                        CallcenterUserLogin: userLoginId
                    }
                })
                .then(ServiceHandler.checkSuccess<AppWorkspaceData>(AppWorkspaceData))
                .catch(ServiceHandler.catchError())
        })
    }
}

export default new AppWorkspaceService()
