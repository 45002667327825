import { Nullable } from "@/core/utils/CustomTypes";
import SerializableObject from "../core/SerializableObject";

class User extends SerializableObject {
    UserFirstName: Nullable<string> = null
    UserLastName: Nullable<string> = null

    CredentialList: Credential[] = []
    RoleList: Role[] = []

    constructor(userFirstName?: string, userLastName?: string, credentialList?: Credential[], roleList?: Role[]) {
        super()

        this.UserFirstName = userFirstName ?? null
        this.UserLastName = userLastName ?? null

        this.CredentialList = credentialList ?? []
        this.RoleList = roleList ?? []
    }

    getCredential(): Nullable<Credential> {
        var mainCredential = this.CredentialList[0]
        return mainCredential ?? null
    }

    getToken(): Nullable<string> {
        return this.getCredential()?.CredentialToken ?? null
    }
}

class Credential extends SerializableObject {
    CredentialLogin: Nullable<string>
    CredentialPlatform: Nullable<string>
    CredentialToken: Nullable<string>

    constructor(credentialLogin?: string, credentialPlatform?: string, credentialToken?: string) {
        super()

        this.CredentialLogin = credentialLogin ?? null
        this.CredentialPlatform = credentialPlatform ?? null
        this.CredentialToken = credentialToken ?? null
    }
}

class Role extends SerializableObject {
    RoleId: Nullable<number> = null
    RoleName: Nullable<string> = null

    constructor(roleId?: number, roleName?: string) {
        super()

        this.RoleId = roleId ?? null
        this.RoleName = roleName ?? null
    }
}

// export default User
export { User, Credential, Role }
