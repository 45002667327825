import { AxiosResponse } from 'axios'
import ServiceError from '@/utils/errors/ServiceError'
import ServiceResponse from './ServiceResponse'
import SerializableObject from './SerializableObject'

const defaultErrorMessage = 'Tenemos problemas para conectar con el servidor'

class ServiceHandler {
  static checkSuccessEmpty() {
    return (response: AxiosResponse<ServiceResponse<null>>)  => {
        if (response.status !== 200) {
            return Promise.reject(new ServiceError(defaultErrorMessage, response.status))
          }
  
          if (+response.data?.Response?.ReturnCode === 200) {
            return {}
          }
          
          return Promise.reject(new ServiceError(
            response.data?.Response?.ReturnMessage ?? defaultErrorMessage, 
            +response.data?.Response?.ReturnCode ?? 500
          ))
    }
}
  
    static checkSuccess<T extends SerializableObject> (type: {new () :T }, preProcess: ((data: T) => void) | null = null) {
        return (response: AxiosResponse<ServiceResponse<T>>)  => {
            if (response.status !== 200) {
                return Promise.reject(new ServiceError(defaultErrorMessage, response.status))
              }
      
              if (+response.data?.Response?.ReturnCode === 200 && response.data?.Data) {
                const data = new type().from(response.data.Data)
                preProcess && preProcess(data)
                return data
              }
              
              return Promise.reject(new ServiceError(
                response.data?.Response?.ReturnMessage ?? defaultErrorMessage, 
                +response.data?.Response?.ReturnCode ?? 500,
                new type().from(response.data?.Data)
              ))
        }
    }

    static catchError() {
        return (error: any) => {
            return Promise.reject(new ServiceError(
                error.message ?? defaultErrorMessage, 
                error.response?.code ?? 500,
                error.response?.data
              ))
        }
    }
}

export default ServiceHandler
