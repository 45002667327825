
import { computed, defineComponent, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'

import KTUserMenu from '@/layout/header/partials/UserMenu.vue'
import { ServiceType } from '@/store/modules/ServiceTypeModule'
import { Actions } from '@/store/enums/StoreEnums'
import { CartConfigInfo } from '@/store/modules/CartModule'
import { CustomEvents } from '@/core/utils/CustomTypes'
import { Emitter } from 'mitt'

export default defineComponent({
  name: 'topbar',
  components: {
    KTUserMenu
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const emitter = inject<Emitter<CustomEvents>>('emitter')

    const isNewOrderView = computed(() => route.name === 'new-order')
    const currentServiceType = computed<ServiceType>(
      () => store.getters.currentServiceType
    )

    const chatCount = computed<number>(
      () => store.getters.currentChatCount
    )

    const phoneCount = computed<number>(
      () => store.getters.currentPhoneCount
    )

    const cartConfig = computed<CartConfigInfo>(
      () => store.getters.currentConfig
    )

    const setServiceType = (serviceType: ServiceType) => {
      if (!cartConfig.value.Client) {
        store.dispatch(Actions.SET_SERVICE_TYPE, serviceType)
        return
      }

      Swal.fire({
        text: 'Tienes una orden en curso ¿Estas seguro que deseas cambiar el tipo de servicio actual?',
        icon: 'warning',
        buttonsStyling: false,
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        confirmButtonText: 'Cambiar',
        customClass: {
          cancelButton: 'btn fw-bold btn-light-danger',
          confirmButton: 'btn fw-bold btn-light-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(Actions.CART_CLEAR_ALL)
          store.dispatch(Actions.SET_SERVICE_TYPE, serviceType)
          emitter?.emit('clearOrder', '')
        }
      })
    }

    return {
      isNewOrderView,
      currentServiceType,
      chatCount,
      phoneCount,
      ServiceType,

      setServiceType
    }
  }
})
