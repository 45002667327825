import NexmoClient, { Member, NXMCall } from 'nexmo-client'

import { CallModel, CallProviderUtil, CallState } from '../CallProviderUtil'

export class VonageUtil extends CallProviderUtil {
  init (): void {
    new NexmoClient({ debug: false })
      .login(this.callProvider.CallSourceUserToken)
      .then(app => {
        app.on('member:call', async (member: Member, call: NXMCall) => {
          const callData = await this.getCallData(call, member)
          this.callReceived?.(callData)
        })

        app.on('call:status:changed', async (call: NXMCall) => {
          console.log(call)

          if (call.status === call.CALL_STATUS.COMPLETED) {
            const callData = await this.getCallData(call)
            callData.state = CallState.Ended

            this.callEnded?.(callData)
          }

          if (call.status === call.CALL_STATUS.TIMEOUT) {
            const callData = await this.getCallData(call)
            callData.state = CallState.Ended

            this.callEnded?.(callData)
          }

          if (call.status === call.CALL_STATUS.ANSWERED) {
            const callData = await this.getCallData(call)
            callData.state = CallState.Ended

            this.callAnswered?.(callData)
          }
        })
      })
      .catch(console.error)
  }

  private async getCallData (call: NXMCall, member?: Member) {
    const events = await call.conversation.getEvents()
    const targetEvent = Array.from(events.items.values())
      .map(e => e.body?.channel?.from)
      .find(from => from?.number && from?.number !== 'Unknown')

    let phone = targetEvent?.number
    phone = phone?.substring((phone.length - 10))

    const callData = new CallModel({
      id: call.id,
      from: phone ?? 'Desconocido',
      callProvider: this.callProvider,
      metadata: { member, call },
      onAnswer: async (call) => {
        try {
          await call.metadata.call.answer()
        } catch (e) {
          console.log(e)
        }
      },
      onHangUp: async (call) => {
        try {
          await call.metadata.call.hangUp()
        } catch (e) {
          console.log(e)
        }
      },
      onToggleMute: async (call) => {
        try {
          await call.metadata.call.conversation.me.mute(!call.isMuted)
        } catch (e) {
          console.log(e)
        }
      }
    })

    return callData
  }
}
