class ServiceError extends Error {
    code: number
    message: string
    data: any

    constructor(message: string, code: number = 500, data: any = null) {
        super(message)

        this.message = message
        this.code = code
        this.data = data
    }
}

export default ServiceError